import React, { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import RecoveryModal from '../../components/modals/Recovery/Recovery';

const PasswordResetPage = () => {
  const { token } = useParams();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const [recoveryModalOpen, setRecoveryModalOpen] = useState(true);

  return (
    <div>
      {recoveryModalOpen && (
        <RecoveryModal close={() => setRecoveryModalOpen(false)} defaultState />
      )}
    </div>
  );
};

export default PasswordResetPage;
