import style from './Slider.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import icon from './assets/logo.jpg';
import { Navigation } from 'swiper/modules';
import Card from './components/Card/Card';

import 'swiper/css';
import './Slider.scss';

const sliderContent = [
  {
    icon: icon,
    name: 'MEME',
    label: 'Memereum',
    score: '2,500',
  },
  {
    icon: icon,
    name: 'MEME',
    label: 'Memereum',
    score: '2,500',
  },
  {
    icon: icon,
    name: 'MEME',
    label: 'Memereum',
    score: '2,500',
  },
  {
    icon: icon,
    name: 'MEME',
    label: 'Memereum',
    score: '2,500',
  },
  {
    icon: icon,
    name: 'MEME',
    label: 'Memereum',
    score: '2,500',
  },
  {
    icon: icon,
    name: 'MEME',
    label: 'Memereum',
    score: '2,500',
  },
  {
    icon: icon,
    name: 'MEME',
    label: 'Memereum',
    score: '2,500',
  },
];

const SearchSlider = () => {
  return (
    <div className={style.slider}>
      <button className={`${style.slider__nav} searchSliderNav_prev`}>
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M6 12L10 8L6 4'
            stroke='#010106'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </svg>
      </button>
      <button className={`${style.slider__nav} searchSliderNav_next`}>
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M6 12L10 8L6 4'
            stroke='#010106'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </svg>
      </button>
      <Swiper
        className={style.slider__item}
        modules={[Navigation]}
        slidesPerView={'auto'}
        spaceBetween={8}
        navigation={{
          prevEl: '.searchSliderNav_prev',
          nextEl: '.searchSliderNav_next',
        }}
      >
        {sliderContent.map((item, index) => (
          <SwiperSlide key={index} className={style.slider__slide}>
            <Card
              icon={item.icon}
              name={item.name}
              label={item.label}
              score={item.score}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SearchSlider;
