import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import WatchlistPage from './pages/Watchlist';
import BoostsPage from './pages/Boosts';
import {
  BoostBuySection,
  BoostedSection,
  HistorySection,
} from './pages/Boosts/sections';
import ContactUsPage from './pages/ContactUs';
import BlogPage from './pages/Blog';
import ArticlePage from './pages/Article';
import AirdropsPage from './pages/Airdrops';
import AdvertisePage from './pages/Advertise';
import NewSection from './pages/Advertise/sections/New/New';
import MainPage from './pages/Main';
import SubmitCoinPage from './pages/SubmitCoin';
import StatisticPage from './pages/Statistic';
import NewlyListedPage from './pages/NewlyListed';
import TopNewTokensPage from './pages/TopNewTokens';
import PresalesPage from './pages/Presales';
import BannerPage from './pages/mobile/Banner';
import PromotedSpotPage from './pages/mobile/PromotedSpot';
import CirclePage from './pages/mobile/Circle';
import EmailPage from './pages/mobile/Email';
import ProjectPage from './pages/Project';
import DictionaryPage from './pages/Dictionary';
import ModalsTest from './pages/ModalsTest';
import ReportListingPage from './pages/ReportListing';
import './scss/style.scss';
import PasswordResetPage from './pages/PasswordResetPage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />, //ready
    children: [
      {
        path: '',
        element: <MainPage />, // ready
      },
      {
        path: 'password-reset/:token',
        element: <PasswordResetPage />,
      },
      {
        path: 'watchlist/:id',
        element: <WatchlistPage />, // ready
      },
      {
        path: 'boosts',
        element: <BoostsPage />, // ready
        children: [
          {
            path: 'buy',
            element: <BoostBuySection />, // ready
          },
          {
            path: 'boosted',
            element: <BoostedSection />, // ready
          },
          {
            path: 'history',
            element: <HistorySection />, // ready
          },
        ],
      },
      {
        path: 'contact',
        element: <ContactUsPage />, // ready
      },
      {
        path: 'blog',
        element: <BlogPage />, //ready
      },
      {
        path: 'blog/:id',
        element: <ArticlePage />, // ready
      },
      {
        path: 'airdrops',
        element: <AirdropsPage />, // ready
      },
      {
        path: 'submit_coin/:id',
        element: <SubmitCoinPage />, // ready
      },
      {
        path: 'submit_coin',
        element: <SubmitCoinPage />, // ready
      },
      {
        path: 'advertise',
        element: <AdvertisePage />, // ready
        children: [
          {
            path: 'new',
            element: <NewSection />, // ready
          },
          {
            path: 'boosted',
            element: <BoostedSection />, // ready
          },
        ],
      },
      {
        path: 'statistic',
        element: <StatisticPage />,
      },
      {
        path: 'newly_listed',
        element: <NewlyListedPage />, // ready
      },
      {
        path: 'top_new_tokens',
        element: <TopNewTokensPage />, // ready
      },
      {
        path: 'presales',
        element: <PresalesPage />, // ready
      },
      {
        path: 'coins/:id',
        element: <ProjectPage />, // data does not correspond to figma design
      },
      {
        path: 'dictionary',
        element: <DictionaryPage />, // ready
      },
      {
        path: 'modals_test',
        element: <ModalsTest />,
      },
      // mobile pages
      {
        path: 'banner',
        element: <BannerPage />,
      },
      {
        path: 'promoted_spot',
        element: <PromotedSpotPage />,
      },
      {
        path: 'circle',
        element: <CirclePage />,
      },
      {
        path: 'email',
        element: <EmailPage />,
      },
      {
        path: 'report-listing',
        element: <ReportListingPage />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
