import Title from "../../components/Title/Title";
import { useGetDictionariesQuery } from "../../store/dictionariesApi";
import style from "./index.module.scss";
import Content from "./modules/Content/Content";
import Top from "./modules/Top/Top";

const DictionaryPage = () => {
  const { data: dictionary } = useGetDictionariesQuery();  

  return (
    <div className={style.dictionary}>
      <div className="__container">
        <Title.H1>Dictionary</Title.H1>
        <div className={style.dictionaryBody}>
          <Top dictionary={dictionary}/>
          {dictionary &&
            dictionary?.dictionaries &&
            Object.entries(dictionary?.dictionaries)?.map(([key, value]) => {
              return (
                <Content id={key} title={key} list={value} />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default DictionaryPage;
