import SearchResults from "./modules/Results/Results";
import SearchSlider from "./modules/Slider/Slider";
import style from "./Search.module.scss";

const Search = ({ isOpen, closeSearch, coinsList }) => {
  
  return (
    <div className={`${style.search} ${isOpen && style.open}`}>
      <div className={style.search__bg} onClick={closeSearch}></div>
      <div className={`${style.con} __container`}>
        <div className={style.searchBody}>
          <SearchSlider />
          <SearchResults list={coinsList} />
        </div>
      </div>
    </div>
  );
};

export default Search;
