import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './API';

export const blogApi = createApi({
  reducerPath: 'blogApi',
  tagTypes: ['Blogs'],
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    getBlogs: builder.query({
      query: () => ({
        url: `/blog`,
        method: 'GET',
      }),
      invalidatesTags: ['Blogs'],
    }),
    getBlogById: builder.query({
      query: (id) => ({
        url: `/blog/${id}`,
        method: 'GET',
      }),
      invalidatesTags: ['Blogs'],
    }),
    getBlogsByCategory: builder.query({
      query: ({ category, page }) => ({
        url: `/blog?category:${category}&page=${page}`,
        method: 'GET',
      }),
      invalidatesTags: ['Blogs'],
    }),
    getBlogArticleBySlug: builder.query({
      query: (slug) => ({
        url: `/blog?article=${slug}`,
        method: 'GET',
      }),
      invalidatesTags: ['Blogs'],
    }),
  }),
});

export const {
  useGetBlogsQuery,
  useGetBlogsByCategoryQuery,
  useGetBlogArticleBySlugQuery,
  useGetBlogByIdQuery,
} = blogApi;
