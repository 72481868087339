import Text from '../../../../components/Text/Text';
import Title from '../../../../components/Title/Title';
import style from './Projects.module.scss';
import img from './assets/img.jpg';
import votesIcon from '../../../../assets/btn_icons/votes.svg';
import viewsIcon from '../../../../assets/btn_icons/visitors.svg';
import boostIcon from '../../../../assets/btn_icons/boost.svg';
import starIcon from '../../../../assets/btn_icons/star-yellow.svg';
import Input from '../../../../components/Input/Input';
import Button from '../../../../components/Button/Button';

const cardList = [
  {
    profile: { icon: img, name: 'LopeCoin', label: 'Lope', chain: 'bsc' },
    stats: { votes: '11,182', boost: 150 },
    address: '0x9f9cc0704872571254de19CA...460D',
    h24: {
      boost: '1,696',
      votes: '227',
    },
  },
  {
    profile: { icon: img, name: 'LopeCoin', label: 'Lope', chain: 'bsc' },
    stats: { votes: '11,182', boost: 150 },
    address: '0x9f9cc0704872571254de19CA...460D',
    h24: {
      boost: '1,696',
      votes: '227',
    },
  },
  {
    profile: { icon: img, name: 'LopeCoin', label: 'Lope', chain: 'bsc' },
    stats: { votes: '11,182', boost: 150 },
    address: '0x9f9cc0704872571254de19CA...460D',
    h24: {
      boost: '1,696',
      votes: '227',
    },
  },
  {
    profile: { icon: img, name: 'LopeCoin', label: 'Lope', chain: 'bsc' },
    stats: { votes: '11,182', boost: 150 },
    address: '0x9f9cc0704872571254de19CA...460D',
    h24: {
      boost: '1,696',
      votes: '227',
    },
  },
];

const ProjectsSection = () => {
  return (
    <div className={style.projects}>
      <div className={style.projectsTop}>
        <Title.H1 className={style.projects__title}>My projects</Title.H1>
        <Button filled>Submit Coin</Button>
      </div>
      <div className={style.projectsBody}>
        {cardList.map((item, index) => (
          <ProjectCard
            key={index}
            profile={item.profile}
            stats={item.stats}
            address={item.address}
            h24={item.h24}
          />
        ))}
      </div>
    </div>
  );
};

const ProjectCard = ({ profile, stats, address, h24 }) => {
  const chainLogo = () => {
    switch (profile.chain) {
      case 'bsc':
        return (
          <svg
            width='9'
            height='9'
            viewBox='0 0 9 9'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M4.2 8.39999C6.51959 8.39999 8.39999 6.51959 8.39999 4.2C8.39999 1.8804 6.51959 0 4.2 0C1.8804 0 0 1.8804 0 4.2C0 6.51959 1.8804 8.39999 4.2 8.39999Z'
              fill='#F3BA2F'
            />
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M5.28907 4.64447L5.92262 5.2762L4.20039 6.99661L2.47997 5.2762L3.11352 4.64447L4.20039 5.73133L5.28907 4.64447ZM4.20039 3.55578L4.84304 4.19844L4.20039 4.84109L3.55956 4.20026V4.19844L3.67243 4.08556L3.72705 4.03095L4.20039 3.55578ZM2.03394 3.56489L2.66749 4.19844L2.03394 4.83016L1.40039 4.19662L2.03394 3.56489ZM6.36684 3.56489L7.00039 4.19844L6.36684 4.83016L5.73329 4.19662L6.36684 3.56489ZM4.20039 1.39844L5.9208 3.11885L5.28725 3.7524L4.20039 2.66372L3.11352 3.75058L2.47997 3.11885L4.20039 1.39844Z'
              fill='#131415'
            />
          </svg>
        );
        break;

      default:
        break;
    }
  };

  return (
    <div className={style.card}>
      <div className={style.cardTop}>
        <div className={style.cardProfile}>
          <div className={style.cardProfile__img}>
            <img src={profile.icon} alt='' />
          </div>
          <div className={style.cardProfile__content}>
            <Text fz14 middle className={style.cardProfile__contentName}>
              {profile.name}
              <div className={style.chain}>{chainLogo()}</div>
            </Text>
            <Text grey className={style.cardProfile__contentLabel}>
              {profile.label}
            </Text>
          </div>
        </div>
        <div className={style.cardStats}>
          <div className={style.cardStats__item}>
            <Text grey>{stats.votes}</Text>
            <img src={viewsIcon} alt='' />
          </div>
          <div className={style.cardStats__item}>
            <Text grey>{stats.boost}</Text>
            <img src={starIcon} alt='' />
          </div>
        </div>
      </div>
      <Input
        disabled
        className={style.card__address}
        value={address}
        withCopy
      />
      <div className={style.cardBody}>
        <div className={`${style.cardBody__item} ${style.yellow}`}>
          <div className={style.cardBody__itemIcon}>
            <img src={boostIcon} alt='' />
          </div>
          <Title.H3 fz16 className={style.cardBody__itemValue}>
            {h24.boost}
          </Title.H3>
          <Text grey className={style.cardBody__itemLabel}>
            Boosts / 24h
          </Text>
          <Button lightYellow className={style.cardBody__itemBtn}>
            Boost Now
          </Button>
        </div>
        <div className={`${style.cardBody__item} ${style.purple}`}>
          <div className={style.cardBody__itemIcon}>
            <img src={votesIcon} alt='' />
          </div>
          <Title.H3 fz16 className={style.cardBody__itemValue}>
            {h24.votes}
          </Title.H3>
          <Text grey className={style.cardBody__itemLabel}>
            Boosts / 24h
          </Text>
          <Button lightPurple className={style.cardBody__itemBtn}>
            Boost Now
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProjectsSection;
