import Button from '../../components/Button/Button';
import Title from '../../components/Title/Title';
import style from './index.module.scss';
import List from './modules/List/List';
import Text from '../../components/Text/Text';

import Select from '../../components/Select/Select';
import Table from '../../components/Table/Table';
import TokenInfo from '../../components/TokenInfo/TokenInfo';
import {
  Chain,
  TypeDiv,
} from '../Main/sections/BestTableSection/BestTableSection';
import { useMediaQuery } from 'usehooks-ts';
import { useEffect, useState } from 'react';
import img from '../../components/Search/modules/Slider/assets/logo.jpg';
import ethIcon from '../../assets/icons/chains/eth.svg';
import bscIcon from '../../assets/icons/chains/bsc.svg';

// import list icons
import img1 from './assets/img1.jpg';
import img2 from './assets/img2.jpg';
import img3 from './assets/img3.jpg';
import img4 from './assets/img4.jpg';
import img5 from './assets/img5.png';
import {
  useAttachWatchlistMutation,
  useDeleteWatchlistMutation,
  useGetWatchlistCoinTableQuery,
  useGetWatchlistQuery,
} from '../../store/watchlistApi';
import {
  useBoostCoinsMutation,
  useGetCoinsTableByTypeQuery,
} from '../../store/coinsApi';
import { useNavigate, useParams } from 'react-router-dom';
import UseGeneralHooks from '../../hooks/useGeneralHooks';

const iconList = [img1, img2, img3, img4, img5];

const WatchlistPage = () => {
  const [isEmpty, setIsEmpty] = useState(false);

  return (
    <section className={style.watchlist}>
      <div className='__container'>
        {isEmpty ? <WatchlistEmpty /> : <WatchlistTable />}
      </div>
    </section>
  );
};

const WatchlistTable = () => {
  const [filterType, setFilterType] = useState(1);
  const [skip, setSkip] = useState(true);
  const isTablet = useMediaQuery('(max-width:991px)');
  const {navigate, params} = UseGeneralHooks();

  const { data: watchList, isSuccess: gotWatchlistIdSuccessfully } =
    useGetWatchlistQuery({ catgory: filterType, id: params?.id });
  const { data: watchlistTableData } = useGetWatchlistCoinTableQuery(
    {
      category: filterType,
      id: params?.id,
    },
    { skip }
  );

  useEffect(() => {
    if (gotWatchlistIdSuccessfully) {
      setSkip(false);
    }
  }, [gotWatchlistIdSuccessfully]);

  const [attachToWatchlist] = useAttachWatchlistMutation();
  const [detachWatchlist] = useDeleteWatchlistMutation();
  const [boostCoinRequest] = useBoostCoinsMutation();

  return (
    <>
      <div className={style.watchlistTop}>
        {isTablet ? (
          <>
            <div className={style.watchlistTop__row}>
              <Title.H1 className={style.watchlistTop__title}>
                Watchlist
              </Title.H1>
              <Button onlyIcon className={style.watchlistTop__share}>
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M5.72667 9.00671L10.28 11.66M10.2733 4.34004L5.72667 6.99337M14 3.33337C14 4.43794 13.1046 5.33337 12 5.33337C10.8954 5.33337 10 4.43794 10 3.33337C10 2.2288 10.8954 1.33337 12 1.33337C13.1046 1.33337 14 2.2288 14 3.33337ZM6 8.00004C6 9.10461 5.10457 10 4 10C2.89543 10 2 9.10461 2 8.00004C2 6.89547 2.89543 6.00004 4 6.00004C5.10457 6.00004 6 6.89547 6 8.00004ZM14 12.6667C14 13.7713 13.1046 14.6667 12 14.6667C10.8954 14.6667 10 13.7713 10 12.6667C10 11.5621 10.8954 10.6667 12 10.6667C13.1046 10.6667 14 11.5621 14 12.6667Z'
                    stroke='white'
                    stroke-opacity='0.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className={style.watchlistTop__row}>
              <Title.H1 className={style.watchlistTop__title}>
                Watchlist
              </Title.H1>
            </div>
            <div className={style.watchlistTop__row}>
              <Text grey>52 items / last updated 12.05.2024</Text>
            </div>
            <div className={style.watchlistTop__row}>
              <div className={style.watchlistTop__rowFilter}>
                {watchlistTableData?.categories?.map((category, index) => {
                  return (
                    <Button
                      onClick={() => setFilterType(category?.id)}
                      filled={filterType === category?.id}
                    >
                      {category?.title}
                    </Button>
                  );
                })}
              </div>
              <Button onlyIcon className={style.watchlistTop__share}>
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M5.72667 9.00671L10.28 11.66M10.2733 4.34004L5.72667 6.99337M14 3.33337C14 4.43794 13.1046 5.33337 12 5.33337C10.8954 5.33337 10 4.43794 10 3.33337C10 2.2288 10.8954 1.33337 12 1.33337C13.1046 1.33337 14 2.2288 14 3.33337ZM6 8.00004C6 9.10461 5.10457 10 4 10C2.89543 10 2 9.10461 2 8.00004C2 6.89547 2.89543 6.00004 4 6.00004C5.10457 6.00004 6 6.89547 6 8.00004ZM14 12.6667C14 13.7713 13.1046 14.6667 12 14.6667C10.8954 14.6667 10 13.7713 10 12.6667C10 11.5621 10.8954 10.6667 12 10.6667C13.1046 10.6667 14 11.5621 14 12.6667Z'
                    stroke='white'
                    stroke-opacity='0.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </Button>
            </div>
          </>
        )}
      </div>
      <Table.Container className={style.watchlistTable}>
        <Table.Head>
          <Table.Row>
            {!isTablet && <Table.Col>#</Table.Col>}
            <Table.Col></Table.Col>
            <Table.Col>Coin</Table.Col>
            {!isTablet && (
              <>
                <Table.Col>Badges</Table.Col>
                <Table.Col center>Chains</Table.Col>
                <Table.Col center>Transactions</Table.Col>
                <Table.Col center>Site Visitors</Table.Col>
                <Table.Col center>
                  <div className={style.head__row}>
                    Current Price
                    <svg
                      width='12'
                      height='12'
                      viewBox='0 0 12 12'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g clip-path='url(#clip0_133_62278)'>
                        <path
                          d='M6 8V6M6 4H6.005M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z'
                          stroke='white'
                          stroke-opacity='0.5'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_133_62278'>
                          <rect width='12' height='12' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </Table.Col>
                <Table.Col>Market Cap</Table.Col>
                <Table.Col center>Change 24h</Table.Col>
                <Table.Col center>Raise amount</Table.Col>
                <Table.Col center>Launch</Table.Col>
              </>
            )}
            <Table.Col></Table.Col>
            <Table.Col center>Total Boosts</Table.Col>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {watchlistTableData?.coins?.data?.map((item, index) => {
            return (
              <Table.Row key={index} onClick={() => navigate(item?.coin?.url)}>
                {!isTablet && <Table.Col>{index + 1}</Table.Col>}
                <Table.Col>
                  <button
                    className={style.favorite}
                    onClick={() =>
                      item?.is_watchlisted
                        ? detachWatchlist(item?.coin?.id)
                        : attachToWatchlist(item?.coin?.id)
                    }
                  >
                    <svg
                      width='14'
                      height='13'
                      viewBox='0 0 14 13'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M6.26134 0.775945C6.53462 0.11891 7.46538 0.118909 7.73866 0.775943L8.92838 3.63637C9.04358 3.91336 9.30407 4.10261 9.6031 4.12659L12.6912 4.37416C13.4005 4.43102 13.6881 5.31623 13.1477 5.77916L10.7949 7.79457C10.5671 7.98974 10.4676 8.29596 10.5372 8.58776L11.256 11.6012C11.4211 12.2934 10.6681 12.8405 10.0608 12.4695L7.417 10.8547C7.16099 10.6983 6.83901 10.6983 6.583 10.8547L3.93918 12.4695C3.3319 12.8405 2.5789 12.2934 2.74401 11.6012L3.46282 8.58776C3.53243 8.29596 3.43293 7.98974 3.2051 7.79458L0.852314 5.77917C0.311884 5.31623 0.599504 4.43102 1.30883 4.37416L4.3969 4.12659C4.69593 4.10261 4.95642 3.91336 5.07162 3.63637L6.26134 0.775945Z'
                        fill='white'
                        fill-opacity='0.1'
                      />
                    </svg>
                  </button>
                </Table.Col>
                <Table.Col>
                  <TokenInfo
                    icon={item?.coin?.image ? item?.coin?.image : img}
                    name={item?.coin?.title}
                    label={item?.coin?.symbol}
                  />
                </Table.Col>
                {!isTablet && (
                  <>
                    <Table.Col>
                      <div className={style.chains}>
                        {item?.badges?.map((badge, index) => {
                          return (
                            <TypeDiv
                              title={badge?.title}
                              key={index}
                              tagColor={badge?.color}
                            />
                          );
                        })}
                      </div>
                    </Table.Col>
                    <Table.Col>
                      <Chain
                        name={item?.network}
                        icon={
                          <svg
                            width='16'
                            height='16'
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z'
                              fill='white'
                              fill-opacity='0.1'
                            />
                            <path
                              d='M7.99902 3V6.69568L10.9978 8.09145L7.99902 3Z'
                              fill='white'
                              fill-opacity='0.5'
                            />
                            <path
                              d='M7.99919 3L5 8.09145L7.99919 6.69568V3Z'
                              fill='white'
                            />
                            <path
                              d='M7.99902 10.4851V12.9963L10.9998 8.67188L7.99902 10.4851Z'
                              fill='white'
                              fill-opacity='0.5'
                            />
                            <path
                              d='M7.99919 12.9963V10.4847L5 8.67188L7.99919 12.9963Z'
                              fill='white'
                            />
                            <path
                              d='M7.99902 9.90393L10.9978 8.09025L7.99902 6.69531V9.90393Z'
                              fill='white'
                              fill-opacity='0.1'
                            />
                            <path
                              d='M5 8.09025L7.99919 9.90393V6.69531L5 8.09025Z'
                              fill='white'
                              fill-opacity='0.5'
                            />
                          </svg>
                        }
                      />
                    </Table.Col>
                    <Table.Col center>{item?.transactions}</Table.Col>
                    <Table.Col center>
                      <Chain
                        name={item?.website_views}
                        reverse
                        smallIcon
                        transparentIcon
                        icon={
                          <svg
                            width='12'
                            height='12'
                            viewBox='0 0 12 12'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M8.4138 0.291703C8.30641 0.252347 8.19552 0.211485 8.07999 0.175844C6.24482 -0.400641 5.22397 0.5433 4.95266 1.51726C4.58097 2.84793 5.21459 3.75965 6.85658 5.10106C8.8694 6.74457 9.70256 8.27092 9.11883 10.3648C8.94667 10.9845 8.6586 11.5402 8.26389 12C9.89063 11.2974 11.2144 9.84416 11.7516 7.91792C12.6195 4.81091 11.1423 1.56073 8.4138 0.291703Z'
                              fill='#FF9700'
                            />
                            <path
                              d='M5.56033 6.32975C3.5978 4.81937 2.57808 3.187 3.13756 1.18055C3.22146 0.882968 3.33273 0.606473 3.46938 0.351562C1.96443 1.0936 0.757025 2.49214 0.248304 4.31246C-0.282671 6.21289 0.0643994 8.16573 1.03686 9.68876C1.70789 10.559 2.35753 11.2126 3.67621 11.5983C5.46184 12.1212 6.90155 11.3948 7.30222 9.95693C7.67467 8.62585 7.16983 7.64085 5.56033 6.32975Z'
                              fill='#2B3D52'
                            />
                          </svg>
                        }
                      />
                    </Table.Col>
                    <Table.Col center>${item?.current_price}</Table.Col>
                    <Table.Col>$ {item?.market_cup}</Table.Col>
                    <Table.Col center>
                      <div className={`${style.diff} ${style.plus}`}>
                        {item?.price_change_percentage_24h}%
                      </div>
                    </Table.Col>
                    <Table.Col center>{item?.raised_amount}</Table.Col>
                    <Table.Col center>
                      {item?.launch ? item?.launch : '-'}
                    </Table.Col>
                  </>
                )}
                <Table.Col center>
                  <Chain
                    transparentIcon
                    name={item?.boosts_count}
                    icon={
                      <svg
                        width='20'
                        height='20'
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M14.9995 10.989C14.9676 8.52734 14.0196 6.82558 13.5049 6.08044C13.3725 5.88879 13.0898 5.91265 12.9873 6.1225C12.8296 6.44481 12.5766 6.85228 12.2321 7.06436C12.2321 7.06436 10.772 5.85628 10.7039 4.04639C10.6962 3.83982 10.4951 3.70022 10.3044 3.76674C9.53128 4.03669 7.89045 4.9435 7.49386 7.83261C7.16787 7.23021 6.39474 7.05422 5.89792 7.0047C5.69091 6.98397 5.5237 7.1804 5.57527 7.38726C5.95385 8.90512 5 9.81208 5 11.1164C5 13.9466 7.23092 16.2419 9.98568 16.25C12.7826 16.2582 15.0367 13.8602 14.9995 10.989Z'
                          fill='url(#paint0_linear_985_6256)'
                        />
                        <g filter='url(#filter0_b_985_6256)'>
                          <path
                            d='M16.2495 9.739C16.2176 7.27734 15.2696 5.57558 14.7549 4.83044C14.6225 4.63879 14.3398 4.66265 14.2373 4.8725C14.0796 5.19481 13.8266 5.60228 13.4821 5.81436C13.4821 5.81436 12.022 4.60628 11.9539 2.79639C11.9462 2.58982 11.7451 2.45022 11.5544 2.51674C10.7813 2.78669 9.14045 3.6935 8.74386 6.58261C8.41787 5.98021 7.64474 5.80422 7.14792 5.7547C6.94091 5.73397 6.7737 5.9304 6.82527 6.13726C7.20385 7.65512 6.25 8.56208 6.25 9.86637C6.25 12.6966 8.48092 14.9919 11.2357 15C14.0326 15.0082 16.2867 12.6102 16.2495 9.739Z'
                            fill='white'
                            fill-opacity='0.1'
                          />
                        </g>
                        <defs>
                          <filter
                            id='filter0_b_985_6256'
                            x='3.95'
                            y='0.2'
                            width='14.6'
                            height='17.1'
                            filterUnits='userSpaceOnUse'
                            color-interpolation-filters='sRGB'
                          >
                            <feFlood
                              flood-opacity='0'
                              result='BackgroundImageFix'
                            />
                            <feGaussianBlur
                              in='BackgroundImageFix'
                              stdDeviation='1.15'
                            />
                            <feComposite
                              in2='SourceAlpha'
                              operator='in'
                              result='effect1_backgroundBlur_985_6256'
                            />
                            <feBlend
                              mode='normal'
                              in='SourceGraphic'
                              in2='effect1_backgroundBlur_985_6256'
                              result='shape'
                            />
                          </filter>
                          <linearGradient
                            id='paint0_linear_985_6256'
                            x1='15.8333'
                            y1='3.75'
                            x2='8.82948'
                            y2='18.1169'
                            gradientUnits='userSpaceOnUse'
                          >
                            <stop stop-color='#F6FF8E' />
                            <stop offset='1' stop-color='#F26B41' />
                          </linearGradient>
                        </defs>
                      </svg>
                    }
                  />
                </Table.Col>
                <Table.Col center>
                  <Button
                    sm
                    filledGrey
                    onClick={() =>
                      boostCoinRequest({ coin: item?.coin?.id })
                    }
                  >
                    Boost
                  </Button>
                </Table.Col>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table.Container>
    </>
  );
};

// const FilterBtn = ({ title, checked = false }) => {
//   const ref = useRef(null);

//   return (
//     <label className={style.radioBtn}>
//       {/* <input ref={ref} type='radio' name='watchlist-filter' /> */}
//       <Button filled={checked}>{title}</Button>
//     </label>
//   );
// };

const WatchlistEmpty = () => {
  return (
    <>
      <div className={style.watchlistTop}>
        <div className={style.watchlistTop__row}>
          <Title.H1 className={style.watchlistTop__title}>Watchlist</Title.H1>
          <Button onlyIcon className={style.watchlistTop__share}>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M5.72667 9.00671L10.28 11.66M10.2733 4.34004L5.72667 6.99337M14 3.33337C14 4.43794 13.1046 5.33337 12 5.33337C10.8954 5.33337 10 4.43794 10 3.33337C10 2.2288 10.8954 1.33337 12 1.33337C13.1046 1.33337 14 2.2288 14 3.33337ZM6 8.00004C6 9.10461 5.10457 10 4 10C2.89543 10 2 9.10461 2 8.00004C2 6.89547 2.89543 6.00004 4 6.00004C5.10457 6.00004 6 6.89547 6 8.00004ZM14 12.6667C14 13.7713 13.1046 14.6667 12 14.6667C10.8954 14.6667 10 13.7713 10 12.6667C10 11.5621 10.8954 10.6667 12 10.6667C13.1046 10.6667 14 11.5621 14 12.6667Z'
                stroke='white'
                stroke-opacity='0.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          </Button>
        </div>
      </div>
      <div className={style.watchlistBody}>
        <List className={style.watchlistBody__list} iconList={iconList} />
        <Title.H2 center className={style.watchlistBody__title}>
          There are no <br /> watchlisted items
        </Title.H2>
        <Text grey center className={style.watchlistBody__descr}>
          Add more and keep track <br /> of your favorite tokens
        </Text>
        <Button className={style.watchlistBody__btn}>Explore Market</Button>
      </div>
    </>
  );
};

export default WatchlistPage;
