import { useEffect, useState } from 'react';
import Text from '../Text/Text';
import style from './BlogCard.module.scss';

const BlogCard = ({ className, img, tag, title, text, info, tagColor, onClick }) => {
  const [dynamicStyle, setDynamicStyle] = useState()

  useEffect(() => {
    setDynamicStyle({
      color: tagColor,
      backgroundColor: `${tagColor}33`, 
    })
  }, [tagColor]);

  return (
    <div className={`${style.card} ${className}`} onClick={onClick}>
      <div className={style.card__img}>
        <img src={img} alt='' />
      </div>
      <div className={style.cardContent}>
        <div className={`${style.cardContent__tag}`} style={dynamicStyle}>
          {tag}
        </div>
        <Text middle className={style.cardContent__title}>
          {title}
        </Text>
        <Text className={style.cardContent__text} grey>
          {text}
        </Text>
        <div className={style.cardContent__info}>
          <div className={style.cardContent__infoItem}>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M14 6.66536H2M10.6667 1.33203V3.9987M5.33333 1.33203V3.9987M5.2 14.6654H10.8C11.9201 14.6654 12.4802 14.6654 12.908 14.4474C13.2843 14.2556 13.5903 13.9497 13.782 13.5733C14 13.1455 14 12.5855 14 11.4654V5.86536C14 4.74526 14 4.18521 13.782 3.75738C13.5903 3.38106 13.2843 3.0751 12.908 2.88335C12.4802 2.66536 11.9201 2.66536 10.8 2.66536H5.2C4.0799 2.66536 3.51984 2.66536 3.09202 2.88335C2.71569 3.0751 2.40973 3.38106 2.21799 3.75738C2 4.18521 2 4.74526 2 5.86536V11.4654C2 12.5855 2 13.1455 2.21799 13.5733C2.40973 13.9497 2.71569 14.2556 3.09202 14.4474C3.51984 14.6654 4.0799 14.6654 5.2 14.6654Z'
                stroke='white'
                stroke-opacity='0.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
            <Text grey>{info.date}</Text>
          </div>
          <div className={style.cardContent__infoItem}>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g>
                <path
                  d='M7.99992 3.9987V7.9987L10.6666 9.33203M14.6666 7.9987C14.6666 11.6806 11.6818 14.6654 7.99992 14.6654C4.31802 14.6654 1.33325 11.6806 1.33325 7.9987C1.33325 4.3168 4.31802 1.33203 7.99992 1.33203C11.6818 1.33203 14.6666 4.3168 14.6666 7.9987Z'
                  stroke='white'
                  stroke-opacity='0.5'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </g>
            </svg>
            <Text grey>{info.time} min</Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
