import { Controller, useForm } from 'react-hook-form';
import { useForgetPasswordMutation } from '../../store/authApi';
import Button from '../Button/Button';
import style from '../modals/Recovery/Recovery.module.scss';
import Input from '../Input/Input';
import { useEffect } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import '../../scss/style.scss';

const ForgetPassword = ({ setIsSwitched, setEmail }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [forgetPasswordReq, { isSuccess, isLoading }] =
    useForgetPasswordMutation();

  const onSubmit = (data) => {
    if (data?.email) {
      forgetPasswordReq({
        email: data?.email,
      });
      setEmail(data?.email);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setIsSwitched(true);
    }
  }, [isSuccess]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={style['forget-password__form']}
    >
      <Controller
        control={control}
        name='email'
        rules={{
          required: 'Required',
          pattern: {
            value: /^\s*[A-ZA-z0-9._%+-]+@[A-Za-z0-9.-]+[A-Za-z]{2,}\s*$/i,
            message: 'Invalid email address',
          },
        }}
        render={({ field: { onChange, name, value } }) => {
          return (
            <Input
              onChange={onChange}
              value={value}
              icon={
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M1.33325 4.66797L4.79581 6.97634C4.99558 7.10952 5.09546 7.1761 5.20352 7.22328C5.29943 7.26515 5.39994 7.29558 5.50297 7.31394C5.61905 7.33464 5.73909 7.33464 5.97917 7.33464H10.0207C10.2607 7.33464 10.3808 7.33464 10.4969 7.31394C10.5999 7.29558 10.7004 7.26515 10.7963 7.22328C10.9044 7.17611 11.0043 7.10951 11.204 6.97634L14.6666 4.66797M4.53325 13.3346H11.4666C12.5867 13.3346 13.1467 13.3346 13.5746 13.1166C13.9509 12.9249 14.2569 12.6189 14.4486 12.2426C14.6666 11.8148 14.6666 11.2547 14.6666 10.1346V5.86797C14.6666 4.74786 14.6666 4.18781 14.4486 3.75999C14.2569 3.38366 13.9509 3.0777 13.5746 2.88596C13.1467 2.66797 12.5867 2.66797 11.4666 2.66797H4.53325C3.41315 2.66797 2.85309 2.66797 2.42527 2.88596C2.04895 3.0777 1.74299 3.38366 1.55124 3.75999C1.33325 4.18781 1.33325 4.74786 1.33325 5.86797V10.1346C1.33325 11.2547 1.33325 11.8148 1.55124 12.2426C1.74299 12.6189 2.04895 12.9249 2.42527 13.1166C2.85309 13.3346 3.41315 13.3346 4.53325 13.3346Z'
                    stroke='white'
                    stroke-opacity='0.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              }
              placeholder={'Email'}
              className={style.input}
              big
              border
            />
          );
        }}
      />
      {errors?.email && (
        <label className='error-message'>{errors?.email?.message}</label>
      )}
      <Button className={style.button__space} filled>
        {isLoading ? (
          <ClipLoader color={'black'} size={15} />
        ) : (
          'Reset password'
        )}
      </Button>
    </form>
  );
};

export default ForgetPassword;
