import style from './Title.module.scss';

const Title = {
  H1: ({ children, className, center, fz42, fz48 }) => {
    return (
      <h1
        className={`${style.title} ${center && style.center}
         ${fz42 && style._42}
         ${fz48 && style._48}
         ${className}`}
      >
        {children}
      </h1>
    );
  },
  H2: ({ children, className, center, blue, grey, fz20 }) => {
    return (
      <h2
        className={`${style.title} ${style.h2} 
        ${center && style.center}
        ${blue && style.blue}
        ${fz20 && style._20}
        ${grey && style.grey}
         ${className}`}
      >
        {children}
      </h2>
    );
  },
  H3: ({ children, className, center, fz16 }) => {
    return (
      <h2
        className={`${style.title} 
        ${style.h3} ${center && style.center}
         ${fz16 && style._16}
         ${className}`}
      >
        {children}
      </h2>
    );
  },
};

export default Title;
