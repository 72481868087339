import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './API';

export const promotionsApi = createApi({
  reducerPath: 'promotionsApi',
  tagTypes: ['Promotions'],
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    getPromotions: builder.query({
      query: (category) => ({
        url: `/promotions?category=${category}`,
        method: 'GET',
      }),
      invalidatesTags: ['Promotions'],
    }),
  }),
});

export const { useGetPromotionsQuery } = promotionsApi;
