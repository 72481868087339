import Title from '../../../../../../components/Title/Title';
import style from './Rate.module.scss';
import img from '../../../Community/assets/quest.svg';
import Text from '../../../../../../components/Text/Text';
import Button from '../../../../../../components/Button/Button';
import {
  useGetCoinByIdQuery,
  useSendFeelingMutation,
} from '../../../../../../store/coinsApi';
import UseGeneralHooks from '../../../../../../hooks/useGeneralHooks';
import { useEffect } from 'react';

const Rate = () => {
  const [sendFeelingReq, { isSuccess }] = useSendFeelingMutation();
  const { params } = UseGeneralHooks();
  const { data: singleCoinData, refetch } = useGetCoinByIdQuery(params?.id);

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess]);

  return (
    <div className={style.rate}>
      <Title.H3 fz16 className={style.rate__title}>
        How do you rate
        <div className={style.rate__titleCoin}>
          <img src={img} alt='' />
          LopeCoin
        </div>
        today?
      </Title.H3>
      <Text grey className={style.rate__text}>
        You can also see how other users feel about the project
      </Text>
      <div className={style.rateBody}>
        <Button
          sm
          className={style.rateBody__item}
          onClick={() =>
            sendFeelingReq({ coin: params?.id, feeling: 'turtle' })
          }
        >
          <span>🐢</span>{' '}
          {singleCoinData?.coin?.feelings?.turtle
            ? singleCoinData?.coin?.feelings?.turtle
            : 0}
        </Button>
        <Button
          sm
          className={style.rateBody__item}
          onClick={() =>
            sendFeelingReq({ coin: params?.id, feeling: 'rocket' })
          }
        >
          <span>🚀</span>{' '}
          {singleCoinData?.coin?.feelings?.rocket
            ? singleCoinData?.coin?.feelings?.rocket
            : 0}
        </Button>
        <Button
          sm
          className={style.rateBody__item}
          onClick={() =>
            sendFeelingReq({ coin: params?.id, feeling: 'unicorn' })
          }
        >
          <span>🦄</span>{' '}
          {singleCoinData?.coin?.feelings?.unicorn
            ? singleCoinData?.coin?.feelings?.unicorn
            : 0}
        </Button>
        <Button
          sm
          className={style.rateBody__item}
          onClick={() => sendFeelingReq({ coin: params?.id, feeling: 'fire' })}
        >
          <span>🔥</span>{' '}
          {singleCoinData?.coin?.feelings?.fire
            ? singleCoinData?.coin?.feelings?.fire
            : 0}
        </Button>
        <Button
          sm
          className={style.rateBody__item}
          onClick={() => sendFeelingReq({ coin: params?.id, feeling: 'paper' })}
        >
          <span>🪽</span>{' '}
          {singleCoinData?.coin?.feelings?.paper
            ? singleCoinData?.coin?.feelings?.paper
            : 0}
        </Button>
        <Button
          sm
          className={style.rateBody__item}
          onClick={() => sendFeelingReq({ coin: params?.id, feeling: 'crown' })}
        >
          <span>👑</span>{' '}
          {singleCoinData?.coin?.feelings?.crown
            ? singleCoinData?.coin?.feelings?.crown
            : 0}
        </Button>
        <Button
          sm
          className={style.rateBody__item}
          onClick={() =>
            sendFeelingReq({ coin: params?.id, feeling: 'glasses' })
          }
        >
          <span>🤿</span>{' '}
          {singleCoinData?.coin?.feelings?.glasses
            ? singleCoinData?.coin?.feelings?.glasses
            : 0}
        </Button>
      </div>
    </div>
  );
};

export default Rate;
