import { useEffect, useState } from "react";

const UseDictionaryHooks = (dictionary) => {
  const [alphabet, setAlphabet] = useState();

  useEffect(() => {
    if (dictionary?.dictionaries) {
      const allLetters = Array.from({ length: 26 }, (_, i) =>
        String.fromCharCode(97 + i)
      );
      const existingLetters = allLetters?.filter(
        (letter) => dictionary?.dictionaries?.[letter]
      );
      setAlphabet(existingLetters);
    }
  }, [dictionary]);

  return {
    alphabet,
  };
};

export default UseDictionaryHooks
