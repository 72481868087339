import { Swiper, SwiperSlide } from "swiper/react";
import Title from "../../../../components/Title/Title";
import Button from "../../../../components/Button/Button";
import Input from "../../../../components/Input/Input";
import UseBlogHooks from "../../../../hooks/useBlogHooks";
import style from "./Top.module.scss";

const BlogTop = () => {
  const {activeCategory, setActiveCategory, allBlogs} = UseBlogHooks()  

  return (
    <div className={style.top}>
      <div className={style.topRow}>
        <Title.H1>Our Blog</Title.H1>
      </div>
      <div className={style.topRow}>
        <Swiper
          className={style.topRow__tabs}
          spaceBetween={8}
          slidesPerView={"auto"}
        >
          {allBlogs?.categories?.map((category, index) => {
            return (
              <SwiperSlide>
                <Button key={index} filled={category.title === activeCategory} onClick={() => setActiveCategory(category.title)}>{category?.title}</Button>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <Input
          className={style.topRow__search}
          placeholder={"Search topic"}
          withClear
          icon={
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 14L11.1 11.1M12.6667 7.33333C12.6667 10.2789 10.2789 12.6667 7.33333 12.6667C4.38781 12.6667 2 10.2789 2 7.33333C2 4.38781 4.38781 2 7.33333 2C10.2789 2 12.6667 4.38781 12.6667 7.33333Z"
                stroke="white"
                stroke-opacity="0.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          }
        />
      </div>
    </div>
  );
};

export default BlogTop;
