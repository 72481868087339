import Text from '../../../../../Text/Text';
import TokenInfo from '../../../../../TokenInfo/TokenInfo';
import style from './Row.module.scss';
import rateSvg from './assets/rate.svg';

const ResultRow = ({ img, name, label, address, rate }) => {
  return (
    <div className={style.row}>
      <TokenInfo icon={img} name={name} label={label} />
      <div className={style.rowAddress}>
        <Text className={style.rowAddress__network} grey>
          BSC
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z'
              fill='white'
              fill-opacity='0.1'
            />
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M10.0755 8.8465L11.2822 10.0498L8.00179 13.3268L4.72481 10.0498L5.93157 8.8465L8.00179 10.9167L10.0755 8.8465ZM8.00179 6.77281L9.22589 7.99691L8.00179 9.22101L6.78116 8.00037V7.99691L6.99616 7.78191L7.10019 7.67788L8.00179 6.77281ZM3.87522 6.79015L5.08198 7.99691L3.87522 9.2002L2.66846 7.99344L3.87522 6.79015ZM12.1284 6.79015L13.3351 7.99691L12.1284 9.2002L10.9216 7.99344L12.1284 6.79015ZM8.00179 2.66357L11.2788 5.94056L10.072 7.14732L8.00179 5.07363L5.93157 7.14385L4.72481 5.94056L8.00179 2.66357Z'
              fill='#FFC700'
            />
          </svg>
        </Text>
        <Text className={style.rowAddress__item} grey>
          <span>{address}</span>
          <button>
            <svg
              width='12'
              height='12'
              viewBox='0 0 12 12'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M3.75 1.5H7.3C8.42011 1.5 8.98016 1.5 9.40798 1.71799C9.78431 1.90973 10.0903 2.21569 10.282 2.59202C10.5 3.01984 10.5 3.5799 10.5 4.7V8.25M3.1 10.5H7.15C7.71005 10.5 7.99008 10.5 8.20399 10.391C8.39215 10.2951 8.54513 10.1422 8.64101 9.95399C8.75 9.74008 8.75 9.46005 8.75 8.9V4.85C8.75 4.28995 8.75 4.00992 8.64101 3.79601C8.54513 3.60785 8.39215 3.45487 8.20399 3.35899C7.99008 3.25 7.71005 3.25 7.15 3.25H3.1C2.53995 3.25 2.25992 3.25 2.04601 3.35899C1.85785 3.45487 1.70487 3.60785 1.60899 3.79601C1.5 4.00992 1.5 4.28995 1.5 4.85V8.9C1.5 9.46005 1.5 9.74008 1.60899 9.95399C1.70487 10.1422 1.85785 10.2951 2.04601 10.391C2.25992 10.5 2.53995 10.5 3.1 10.5Z'
                stroke='white'
                stroke-opacity='0.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          </button>
        </Text>
      </div>
      <Text className={style.row__rate}>
        {rate} <img src={rateSvg} alt='' />
      </Text>
    </div>
  );
};

export default ResultRow;
