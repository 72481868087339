import { useState } from 'react';
import AskModal from '../../components/modals/Ask/Ask';
import BannerModal from '../../components/modals/Banner/Banner';
import CircleModal from '../../components/modals/Circle/Circle';
import EmailModal from '../../components/modals/Email/Email';
import PromotedSpotModal from '../../components/modals/PromotedSpot/PromotedSpot';
import RecoveryModal from '../../components/modals/Recovery/Recovery';
import RegistrationModal from '../../components/modals/Registration/Registration';
import ShareModal from '../../components/modals/Share/Share';
import VerifyModal from '../../components/modals/Verify/Verify';
import style from './index.module.scss';
import Button from '../../components/Button/Button';
import Title from '../../components/Title/Title';
import Notification from '../../components/Notification/Notification';
import mailIcon from '../../assets/icons/notify/mail.svg';

const ModalsTest = () => {
  const [askModalOpen, setAskModalOpen] = useState(false);
  const [bannerModalOpen, setBannerModalOpen] = useState(false);
  const [promotedModalOpen, setPromotedModalOpen] = useState(false);
  const [circleModalOpen, setCircleModalOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [registrationModalOpen, setRegistrationModalOpen] = useState(false);
  const [recoveryModalOpen, setRecoveryModalOpen] = useState(false);
  const [verifyModalOpen, setVerifyModalOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [notifyOpen, setNotifyOpen] = useState(false);

  const callNotify = () => {
    setNotifyOpen(true);

    setTimeout(() => {
      setNotifyOpen(false);
    }, 5000);
  };

  return (
    <>
      {askModalOpen && <AskModal close={() => setAskModalOpen(false)} />}
      {bannerModalOpen && (
        <BannerModal close={() => setBannerModalOpen(false)} />
      )}
      {promotedModalOpen && (
        <PromotedSpotModal close={() => setPromotedModalOpen(false)} />
      )}
      {circleModalOpen && (
        <CircleModal close={() => setCircleModalOpen(false)} />
      )}
      {emailModalOpen && <EmailModal close={() => setEmailModalOpen(false)} />}
      {/* {registrationModalOpen && (
        <RegistrationModal close={() => setRegistrationModalOpen(false)} />
      )} */}
      {/* {recoveryModalOpen && (
        <RecoveryModal close={() => setRecoveryModalOpen(false)} />
      )} */}
      {verifyModalOpen && (
        <VerifyModal close={() => setVerifyModalOpen(false)} />
      )}
      {/* {shareModalOpen && <ShareModal close={() => setShareModalOpen(false)} />} */}
      {notifyOpen && (
        <Notification
          icon={mailIcon}
          text={"We've resent the message with the code"}
        />
      )}
      <div className={style.modals}>
        <div className='__container'>
          <Title.H1 center>Modals</Title.H1>
          <div className={style.modalsBody}>
            <Button onClick={() => setAskModalOpen(true)} filledGrey>
              Open Ask Modal
            </Button>
            <Button onClick={() => setBannerModalOpen(true)} filledGrey>
              Open Banner Modal
            </Button>
            <Button onClick={() => setPromotedModalOpen(true)} filledGrey>
              Open Promoted Modal
            </Button>
            <Button onClick={() => setCircleModalOpen(true)} filledGrey>
              Open Circle Modal
            </Button>
            <Button onClick={() => setEmailModalOpen(true)} filledGrey>
              Open Email Modal
            </Button>
            {/* <Button onClick={() => setRegistrationModalOpen(true)} filledGrey>
              Open Registration Modal
            </Button> */}
            {/* <Button onClick={() => setRecoveryModalOpen(true)} filledGrey>
              Open Recovery Modal
            </Button> */}
            <Button onClick={() => setVerifyModalOpen(true)} filledGrey>
              Open Verify Modal
            </Button>
            {/* <Button onClick={() => setShareModalOpen(true)} filledGrey>
              Open Share Modal
            </Button> */}
          </div>
          <Title.H1 center>Notification</Title.H1>
          <div className={style.modalsBody}>
            <Button onClick={callNotify} filledGrey>
              Open Code Notify Modal
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalsTest;
