import style from './Banners.module.scss';
import Text from '../../../../components/Text/Text';

const Banners = {
  Container: ({ children, withContacts, className }) => {
    return (
      <div className={`${style.container} ${className}`}>
        {children}
        {withContacts && (
          <div className={style.containerContacts}>
            <Text grey>Do you want to be here?</Text>
            <a href=''>
              <Text middle>Contact us</Text>
            </a>
          </div>
        )}
      </div>
    );
  },
  Card: ({ img, half }) => {
    return (
      <div className={`${style.banner} ${half && style.half}`}>
        <img src={img} alt='' />
      </div>
    );
  },
};

export default Banners;
