import { useEffect, useRef, useState } from 'react';
import Text from '../../../../components/Text/Text';
import Title from '../../../../components/Title/Title';
import style from './NewCard.module.scss';

const NewCard = ({
  id,
  icons,
  icon,
  name,
  text,
  price,
  label,
  showPos,
  choose,
  dates,
  selectedCards,
  className,
}) => {
  const inputRef = useRef(null);
  const [isChecked, setIsChecked] = useState(false);

  const chooseHandle = () => {
    if (inputRef.current.checked) {
      choose(
        { id, icons, icon, name, text, price, label, showPos, dates },
        'add'
      );
    } else {
      choose(
        { id, icons, icon, name, text, price, label, showPos, dates },
        'remove'
      );
    }
  };

  useEffect(() => {
    if (selectedCards.filter((card) => card.id === id).length >= 1) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [isChecked, selectedCards, id]);

  return (
    <label className={`${style.card} ${className}`} onClick={chooseHandle}>
      <input type='checkbox' checked={isChecked} ref={inputRef} />
      <div className={style.cardWrapper}>
        <div className={style.cardIcons}>
          {icons.some((item) => item === 'link') && (
            <svg
              width='12'
              height='12'
              viewBox='0 0 12 12'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g>
                <path
                  d='M5.0001 6.50194C5.21483 6.789 5.48878 7.02653 5.80337 7.19841C6.11797 7.37028 6.46585 7.47249 6.82342 7.4981C7.181 7.52371 7.53989 7.47212 7.87577 7.34683C8.21165 7.22153 8.51666 7.02547 8.7701 6.77194L10.2701 5.27194C10.7255 4.80043 10.9775 4.16892 10.9718 3.51343C10.9661 2.85794 10.7032 2.23091 10.2396 1.76739C9.77613 1.30387 9.14909 1.04095 8.4936 1.03525C7.83811 1.02955 7.20661 1.28154 6.7351 1.73694L5.8751 2.59194M7.0001 5.50194C6.78537 5.21487 6.51142 4.97734 6.19682 4.80547C5.88223 4.63359 5.53435 4.53138 5.17677 4.50577C4.8192 4.48016 4.46031 4.53175 4.12443 4.65705C3.78855 4.78234 3.48354 4.9784 3.2301 5.23194L1.7301 6.73194C1.2747 7.20344 1.02272 7.83495 1.02841 8.49044C1.03411 9.14593 1.29703 9.77296 1.76055 10.2365C2.22407 10.7 2.8511 10.9629 3.5066 10.9686C4.16209 10.9743 4.79359 10.7223 5.2651 10.2669L6.1201 9.41194'
                  stroke='white'
                  stroke-opacity='0.5'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </g>
            </svg>
          )}
          {icons.some((item) => item === 'copy') && (
            <svg
              width='12'
              height='12'
              viewBox='0 0 12 12'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M7 1.13473V3.2C7 3.48003 7 3.62004 7.0545 3.727C7.10243 3.82108 7.17892 3.89757 7.273 3.9455C7.37996 4 7.51997 4 7.8 4H9.86527M10 4.99411V8.6C10 9.44008 10 9.86012 9.83651 10.181C9.6927 10.4632 9.46323 10.6927 9.18099 10.8365C8.86012 11 8.44008 11 7.6 11H4.4C3.55992 11 3.13988 11 2.81901 10.8365C2.53677 10.6927 2.3073 10.4632 2.16349 10.181C2 9.86012 2 9.44008 2 8.6V3.4C2 2.55992 2 2.13988 2.16349 1.81901C2.3073 1.53677 2.53677 1.3073 2.81901 1.16349C3.13988 1 3.55992 1 4.4 1H6.00589C6.37277 1 6.55622 1 6.72885 1.04145C6.8819 1.07819 7.02822 1.1388 7.16243 1.22104C7.3138 1.3138 7.44352 1.44352 7.70294 1.70294L9.29706 3.29706C9.55648 3.55648 9.6862 3.6862 9.77896 3.83757C9.8612 3.97178 9.92181 4.1181 9.95855 4.27115C10 4.44378 10 4.62723 10 4.99411Z'
                stroke='white'
                stroke-opacity='0.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          )}
          {icons.some((item) => item === 'rows') && (
            <svg
              width='12'
              height='12'
              viewBox='0 0 12 12'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1.5 6H10.5M3.9 1.5H8.1C8.94008 1.5 9.36012 1.5 9.68099 1.66349C9.96323 1.8073 10.1927 2.03677 10.3365 2.31901C10.5 2.63988 10.5 3.05992 10.5 3.9V8.1C10.5 8.94008 10.5 9.36012 10.3365 9.68099C10.1927 9.96323 9.96323 10.1927 9.68099 10.3365C9.36012 10.5 8.94008 10.5 8.1 10.5H3.9C3.05992 10.5 2.63988 10.5 2.31901 10.3365C2.03677 10.1927 1.8073 9.96323 1.66349 9.68099C1.5 9.36012 1.5 8.94008 1.5 8.1V3.9C1.5 3.05992 1.5 2.63988 1.66349 2.31901C1.8073 2.03677 2.03677 1.8073 2.31901 1.66349C2.63988 1.5 3.05992 1.5 3.9 1.5Z'
                stroke='white'
                stroke-opacity='0.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          )}
        </div>
        <div className={style.cardIcon}>
          <img src={icon} alt='' />
        </div>
        <Title.H3 className={style.cardName}>{name}</Title.H3>
        <Text className={style.cardText} grey>
          {text}
        </Text>
        <div className={style.cardPrice}>
          <Title.H2 className={style.cardPrice__item}>{price}</Title.H2>
          {label && (
            <Text className={style.cardPrice__label} fz14 grey>
              / {label}
            </Text>
          )}
          {showPos && (
            <button className={style.cardPrice__show}>
              <Text>Show position</Text>
            </button>
          )}
        </div>
      </div>
    </label>
  );
};

export default NewCard;
