import UseDictionaryHooks from "../../../../hooks/useDictionaryHooks";
import Button from "../../../../components/Button/Button";
import Input from "../../../../components/Input/Input";
import style from "./Top.module.scss";

const Top = ({ dictionary }) => {
  const toSection = (id) => {
    document.querySelector(`#${id}`)?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const { alphabet } = UseDictionaryHooks(dictionary);

  return (
    <div className={style.top}>
      <Input
        className={style.top__search}
        icon={
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 14L11.1 11.1M12.6667 7.33333C12.6667 10.2789 10.2789 12.6667 7.33333 12.6667C4.38781 12.6667 2 10.2789 2 7.33333C2 4.38781 4.38781 2 7.33333 2C10.2789 2 12.6667 4.38781 12.6667 7.33333Z"
              stroke="white"
              stroke-opacity="0.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        }
        placeholder={"Search term"}
      />
      <div className={style.topBody}>
        {alphabet?.map((item, index) => (
          <Button
            key={index}
            onlyIcon
            filledGrey
            className={style.topBody__btn}
            onClick={() => toSection(item)}
          >
            {item}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default Top;
