import Calendar from '../../../components/Calendar/Calendar';
import style from '../Banner/index.module.scss';
import icon from '../../../components/modals/Email/assets/img.svg';
import projectIcon from '../../../components/modals/PromotedSpot/assets/project-img.jpg';
import stars from '../../../components/modals/Banner/assets/stars.svg';
import Title from '../../../components/Title/Title';
import Text from '../../../components/Text/Text';
import UploadImg from '../../../components/UploadImg/UploadImg';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';
import MobilePageHeading from '../../../components/MobilePageHeading/MobilePageHeading';
import Select from '../../../components/Select/Select';

const EmailPage = () => {
  return (
    <div className={style.block}>
      <div className='__container'>
        <div className={style.blockBody}>
          <MobilePageHeading
            title={'Reserve order'}
            text={'Pick available days and upload resources'}
          />
          <Calendar className={style.blockBody__calendar} />
          <div className={style.banner}>
            <div className={`${style.banner__icon} ${style.email}`}>
              <img src={icon} alt='' />

              {/* <div className={style.banner__iconStars}>
            <img src={stars} alt='' />
          </div> */}
            </div>
            <Title.H2 className={`${style.banner__title} ${style.email}`}>
              Promoted Circle
            </Title.H2>
            <Text grey center>
              Your coin will appear in <br /> Promoted section on main page
            </Text>
            <Input big border placeholder={'Add document'} fileUpload />
            <Button className={style.banner__add} filled>
              Add to Cart
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailPage;
