import { useMediaQuery } from 'usehooks-ts';
import UseBlogHooks from '../../hooks/useBlogHooks';
import Button from '../Button/Button';
import style from './Pagination.module.scss';
import { useEffect, useState } from 'react';

const Pagination = ({ className, setPage, page, total }) => {
  const isTablet = useMediaQuery('(max-width:991px)');
  const [pagesArr, setPagesArr] = useState();

  useEffect(() => {
    const pagesCount = Math.ceil(total / 8);
    const arr = Array.from({ length: pagesCount }, (_, index) => index + 1);
    setPagesArr(arr);
  }, [total]);

  return (
    <div className={`${style.pagination} ${className}`}>
      <button
        className={style.pagination__nav}
        onClick={() => setPage((prev) => prev - 1)}
        disabled={page === 1}
      >
        {isTablet ? (
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M10 4L6 8L10 12'
              stroke='white'
              stroke-opacity='0.5'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
        ) : (
          'Previous Page'
        )}
      </button>
      {pagesArr?.map((item, index) => {
        return (
          <Button
            sm
            onlyIcon
            filled={item === page}
            key={index}
            onClick={() => setPage(item)}
          >
            {item}
          </Button>
        );
      })}
      <button
        className={style.pagination__nav}
        onClick={() => setPage((prev) => prev + 1)}
        disabled={page === pagesArr?.[pagesArr?.length - 1]}
      >
        {isTablet ? (
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M6 12L10 8L6 4'
              stroke='white'
              stroke-opacity='0.5'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
        ) : (
          'Next Page'
        )}
      </button>
    </div>
  );
};

export default Pagination;
