import { useMediaQuery } from 'usehooks-ts';
import ProfileCard from '../../components/ProfileCard/ProfileCard';
import VideoBlock from '../../components/VideoBlock/VideoBlock';
import style from './Info.module.scss';
import avatar from './assets/avatar.jpg';
import PresaleTimer from '../../components/PresaleTimer/PresaleTimer';
import Title from '../../../../components/Title/Title';
import Text from '../../../../components/Text/Text';
import VoteBoost from './modules/VoteBoost/VoteBoost';
import Traffic from './modules/Traffic/Traffic';

const ProjectInfo = ({ coinByIdInfo }) => {
  const isTablet = useMediaQuery('(max-width:991px)');

  return (
    <section className={style.info}>
      <div className='__container'>
        <div className={style.infoBody}>
          <div className={style.infoBody__col}>
            <ProfileCard
              avatar={coinByIdInfo?.image ? coinByIdInfo?.image : avatar}
              coinByIdInfo={coinByIdInfo}
            />
            {!isTablet && (
              <VideoBlock
                src={coinByIdInfo?.links?.youtube?.replace('watch', 'embed')}
              />
            )}
          </div>
          <div className={style.infoBody__col}>
            <div className={style.infoBody__row}>
              <div className={style.infoBody__rowCol}>
                <PresaleTimer presales={coinByIdInfo?.presale} />
              </div>
              <div className={style.infoBody__rowCol}>
                <ValueLinkCard
                  value={coinByIdInfo?.holders}
                  text={'Holders'}
                  link={'https://www.similarweb.com/ru/'}
                />
                <ValueLinkCard
                  value={coinByIdInfo?.transactions_count}
                  text={'Total Transfers'}
                  tip
                  link={'https://www.similarweb.com/ru/'}
                />
              </div>
            </div>
            <VoteBoost coinByIdInfo={coinByIdInfo} />
            <Traffic />
          </div>
        </div>
      </div>
    </section>
  );
};

const ValueLinkCard = ({ value, text, tip, link }) => {
  return (
    <div className={style.card}>
      <div className={style.card__icon}>
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          onClick={() => window.open(link, '_blank')}
        >
          <path
            d='M14 6L14 2M14 2H10M14 2L8.66667 7.33333M6.66667 3.33333H5.2C4.0799 3.33333 3.51984 3.33333 3.09202 3.55132C2.71569 3.74307 2.40973 4.04903 2.21799 4.42535C2 4.85318 2 5.41323 2 6.53333V10.8C2 11.9201 2 12.4802 2.21799 12.908C2.40973 13.2843 2.71569 13.5903 3.09202 13.782C3.51984 14 4.0799 14 5.2 14H9.46667C10.5868 14 11.1468 14 11.5746 13.782C11.951 13.5903 12.2569 13.2843 12.4487 12.908C12.6667 12.4802 12.6667 11.9201 12.6667 10.8V9.33333'
            stroke='#878789'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </svg>
      </div>
      <Title.H3 fz16 className={style.card__value}>
        {value}
      </Title.H3>
      <Text className={style.card__text} grey>
        {text}
        {tip && (
          <svg
            width='12'
            height='12'
            viewBox='0 0 12 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g clip-path='url(#clip0_1425_16664)'>
              <path
                d='M6 8V6M6 4H6.005M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z'
                stroke='#878789'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </g>
            <defs>
              <clipPath id='clip0_1425_16664'>
                <rect width='12' height='12' fill='white' />
              </clipPath>
            </defs>
          </svg>
        )}
      </Text>
    </div>
  );
};

export default ProjectInfo;
