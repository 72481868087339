import Text from '../../../../../../components/Text/Text';
import Title from '../../../../../../components/Title/Title';
import style from './VoteBoost.module.scss';
import Button from '../../../../../../components/Button/Button';
import boostIcon from '../../../../../../assets/btn_icons/boost.svg';
import voteIcon from '../../../../../../assets/btn_icons/votes.svg';
import {
  useBoostCoinsMutation,
  useVoteCoinsMutation,
} from '../../../../../../store/coinsApi';

const VoteBoost = ({ coinByIdInfo }) => {
  const [voteRequest] = useVoteCoinsMutation();
  const [boostRequest] = useBoostCoinsMutation();

  return (
    <div className={style.card}>
      <Title.H3 center fz16>
        Vote & Boost
      </Title.H3>
      <Text grey center className={style.cardBottom}>
        <Text semiBold className={`${style.cardBottom__rank} ${style.yellow}`}>
          64
        </Text>
        Rank / 24h
      </Text>
      <div className={style.cardBody}>
        <div className={style.cardBody__item}>
          <div className={style.cardBody__itemIcon}>
            <img src={boostIcon} alt='' />
          </div>
          <Title.H3 fz16 className={style.cardBody__itemValue}>
            {coinByIdInfo?.boosts_today}
            <div className={`${style.diff} ${style.green}`}>
              <svg
                width='6'
                height='4'
                viewBox='0 0 6 4'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M2.56699 0.25C2.75944 -0.0833333 3.24056 -0.0833332 3.43301 0.25L5.16506 3.25C5.35751 3.58333 5.11695 4 4.73205 4H1.26795C0.883049 4 0.642487 3.58333 0.834937 3.25L2.56699 0.25Z'
                  fill='#78E053'
                />
              </svg>
              12
            </div>
          </Title.H3>
          <Text grey className={style.cardBody__itemText}>
            Boosts / 24h
          </Text>
          <Button
            lightYellow
            className={`${style.cardBody__itemBtn} ${style.yellow}`}
            onClick={() => boostRequest({ coin: coinByIdInfo?.id })}
          >
            Boost Now
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M7.99992 3.33203V12.6654M3.33325 7.9987H12.6666'
                stroke='#F5CE75'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          </Button>
        </div>
        <div className={style.cardBody__item}>
          <div className={style.cardBody__itemIcon}>
            <img src={voteIcon} alt='' />
          </div>
          <Title.H3 fz16 className={style.cardBody__itemValue}>
            {coinByIdInfo?.votes_today}
          </Title.H3>
          <Text grey className={style.cardBody__itemText}>
            Votes / 24h
          </Text>
          <Button
            lightPurple
            className={`${style.cardBody__itemBtn} ${style.purple}`}
            onClick={() => voteRequest(coinByIdInfo?.id)}
            disabled={coinByIdInfo?.is_voted}
          >
            Vote Now
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M7.99992 3.33203V12.6654M3.33325 7.9987H12.6666'
                stroke='#AD75F5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default VoteBoost;
