import { useState } from "react";
import Input from "../../../../components/Input/Input";
import Textarea from "../../../../components/Textarea/Textarea";
import AddCard from "../../components/AddCard/AddCard";
import SubmitCoinCard from "../../modules/Card/Card";
import style from "./Audit.module.scss";
import { Controller } from "react-hook-form";

const AuditSection = ({ control, auditFields, addNewAudit, removeAudit }) => {
  const [show, setShow] = useState(false);
  function onRemove() {
    setShow(false);
    removeAudit();
  }

  return (
    <>
      <AddCard
        onClick={() => {
          addNewAudit();
          setShow(true);
        }}
        icon={
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.3334 6.33301V4.53301C13.3334 3.4129 13.3334 2.85285 13.1154 2.42503C12.9237 2.0487 12.6177 1.74274 12.2414 1.55099C11.8136 1.33301 11.2535 1.33301 10.1334 1.33301H5.86675C4.74664 1.33301 4.18659 1.33301 3.75877 1.55099C3.38244 1.74274 3.07648 2.0487 2.88474 2.42503C2.66675 2.85285 2.66675 3.4129 2.66675 4.53301V11.4663C2.66675 12.5864 2.66675 13.1465 2.88474 13.5743C3.07648 13.9506 3.38244 14.2566 3.75877 14.4484C4.18659 14.6663 4.74664 14.6663 5.86675 14.6663H9.33341M11.0001 10.0012C11.1175 9.66725 11.3494 9.38568 11.6546 9.20633C11.9597 9.02697 12.3185 8.96141 12.6674 9.02125C13.0163 9.08109 13.3327 9.26248 13.5607 9.53328C13.7887 9.80408 13.9134 10.1468 13.9129 10.5008C13.9129 11.5 12.414 11.9997 12.414 11.9997M12.4334 13.9997H12.4401"
              stroke="white"
              stroke-opacity="0.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        }
        title={"Audit"}
      />
      {show && (
        <>
          <SubmitCoinCard
            onRemove={onRemove}
            title={"Submit Audit"}
            className={style.audit}
          >
            <div className={style.auditBody}>
              {auditFields?.map((field, index) => (
                <div
                  className={style.auditBody}
                  key={`audits-${field.id}-${index}`}
                >
                  <Controller
                    control={control}
                    name={`audits.${index}.link`}
                    rules={{
                      required: false,
                    }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Input
                          onChange={onChange}
                          value={value}
                          big
                          border
                          icon={
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.99992 11.3327H4.66659C2.82564 11.3327 1.33325 9.8403 1.33325 7.99935C1.33325 6.1584 2.82564 4.66602 4.66659 4.66602H5.99992M9.99992 11.3327H11.3333C13.1742 11.3327 14.6666 9.8403 14.6666 7.99935C14.6666 6.1584 13.1742 4.66602 11.3333 4.66602H9.99992M4.66659 7.99935L11.3333 7.99935"
                                stroke="white"
                                stroke-opacity="0.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          }
                          placeholder={"Certificate Link: https://"}
                        />
                      );
                    }}
                  />
                  <Controller
                    control={control}
                    name={`audits.${index}.comment`}
                    rules={{
                      required: false,
                    }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Textarea
                          onChange={onChange}
                          value={value}
                          big
                          border
                          placeholder={"Comment (For moderation)"}
                        />
                      );
                    }}
                  />
                </div>
              ))}
            </div>
          </SubmitCoinCard>
        </>
      )}
    </>
  );
};

export default AuditSection;
