export function countHidden(element) {
  const elementRect = element.getBoundingClientRect();
  const elementHides = {
    up: Math.max(0, 0 - elementRect.top),
    left: Math.max(0, 0 - elementRect.left),
    down: Math.max(0, elementRect.bottom - window.innerHeight),
    right: Math.max(0, elementRect.right - window.innerWidth),
  };
  return elementHides;
}
