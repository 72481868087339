import style from './VideoBlock.module.scss';

const VideoBlock = ({ src }) => {
  return (
    <div className={style.video}>
      <iframe
        src={src}
        title='YouTube video player'
        frameborder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        referrerpolicy='strict-origin-when-cross-origin'
        allowfullscreen
      ></iframe>
    </div>
  );
};

export default VideoBlock;
