import axios from 'axios';
import Cookies from 'js-cookie';

const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: '' }) =>
  async ({ url, method, data, params }) => {
    axios.interceptors.response.use(
      (res) => {
        if (res?.data?.error) {
          return Promise.reject(res?.data?.error);
        }
        return res;
      },
      async (err) => {
        // if (err.response.status === 403 || err.response.status === 401) {
        //   window.location.replace("/");
        // }
        return Promise.reject(err);
      }
    );

    axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('coin-gems-access-token');
        if (config.url !== '/login' && config?.headers && token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        const csrfToken = Cookies.get('XSRF-TOKEN'); // Get the CSRF token from cookies

        if (csrfToken) {
          config.headers['X-XSRF-TOKEN'] = csrfToken; // Add it to the request headers
        }

        config.headers['Accept'] = 'application/json';
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params,
        withCredentials: true,
      });
      return { data: result.data };
    } catch (axiosError) {
      return {
        error: {
          error: axiosError.response?.status,
          result: axiosError.response?.data,
        },
      };
    }
  };

export const API = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
  responseType: 'json',
});

export default axiosBaseQuery;
