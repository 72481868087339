import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "./API";

export const airdropsApi = createApi({
  reducerPath: "airdropsApi",
  tagTypes: ["Airdrops"],
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    getAirdrops: builder.query({
      query: (category) => ({        
        url: `/coins-table/airdrops?category=${category}`,
        method: "GET",
      }),
      invalidatesTags: ["Airdrops"],
    }),
  }),
});

export const { useGetAirdropsQuery } = airdropsApi;