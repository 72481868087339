import Text from '../../../../../../components/Text/Text';
import Title from '../../../../../../components/Title/Title';
import style from './Traffic.module.scss';
import graphImg from './assets/graph.svg';

const Traffic = () => {
  return (
    <div className={style.traffic}>
      <div className={style.traffic__link}>
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M14 6L14 2M14 2H10M14 2L8.66667 7.33333M6.66667 3.33333H5.2C4.0799 3.33333 3.51984 3.33333 3.09202 3.55132C2.71569 3.74307 2.40973 4.04903 2.21799 4.42535C2 4.85318 2 5.41323 2 6.53333V10.8C2 11.9201 2 12.4802 2.21799 12.908C2.40973 13.2843 2.71569 13.5903 3.09202 13.782C3.51984 14 4.0799 14 5.2 14H9.46667C10.5868 14 11.1468 14 11.5746 13.782C11.951 13.5903 12.2569 13.2843 12.4487 12.908C12.6667 12.4802 12.6667 11.9201 12.6667 10.8V9.33333'
            stroke='#878789'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </svg>
      </div>
      <Title.H3 center fz16 className={style.traffic__title}>
        Traffic & Engagement
      </Title.H3>
      <Text grey className={style.traffic__text}>
        Check out the statistics
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M11.2185 0.388944C11.0754 0.336469 10.9275 0.281985 10.7735 0.234463C8.32658 -0.534197 6.96545 0.724413 6.60371 2.02305C6.10812 3.79731 6.95295 5.01295 9.14225 6.80154C11.826 8.99293 12.9369 11.0281 12.1586 13.82C11.929 14.6462 11.5449 15.3872 11.0187 16.0003C13.1876 15.0635 14.9527 13.1258 15.669 10.5574C16.826 6.41467 14.8565 2.08101 11.2185 0.388944Z'
            fill='#FF9700'
          />
          <path
            d='M7.41373 8.43982C4.79703 6.42593 3.43742 4.2494 4.18339 1.57408C4.29526 1.1773 4.44362 0.808636 4.62582 0.46875C2.61923 1.45816 1.00936 3.32291 0.33107 5.75004C-0.376893 8.284 0.0858653 10.8878 1.38247 12.9186C2.27718 14.0789 3.14335 14.9504 4.90158 15.4647C7.28241 16.1619 9.20201 15.1934 9.73623 13.2761C10.2328 11.5013 9.55971 10.188 7.41373 8.43982Z'
            fill='#2B3D52'
          />
        </svg>
      </Text>
      <div className={style.trafficInfo}>
        <Title.H3 fz16 className={style.trafficInfo__title}>
          1.618M
        </Title.H3>
        <Text grey className={style.trafficInfo__diff}>
          <div className={`${style.diff} ${style.green}`}>
            <svg
              width='6'
              height='5'
              viewBox='0 0 6 5'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M2.56699 0.75C2.75944 0.416667 3.24056 0.416667 3.43301 0.75L5.16506 3.75C5.35751 4.08333 5.11695 4.5 4.73205 4.5H1.26795C0.883049 4.5 0.642487 4.08333 0.834937 3.75L2.56699 0.75Z'
                fill='#78E053'
              />
            </svg>{' '}
            28.57%
          </div>
          last month visits
        </Text>
        <div className={style.trafficInfo__chart}>
          <img src={graphImg} alt='' />
        </div>
      </div>
    </div>
  );
};

export default Traffic;
