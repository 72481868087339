import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './API';

export const homeApi = createApi({
  reducerPath: 'homeApi',
  tagTypes: ['Home'],
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    getHomePageInfo: builder.query({
      query: () => ({
        url: `/`,
        method: 'GET',
      }),
      invalidatesTags: ['Home'],
    }),
    getLayoutInfo: builder.query({
      query: () => ({
        url: `/layout`,
        method: 'GET',
      }),
      invalidatesTags: ['Home'],
    }),
  }),
});

export const { useGetHomePageInfoQuery, useGetLayoutInfoQuery } = homeApi;
