import { Controller } from 'react-hook-form';
import Input from '../../../../components/Input/Input';
import Text from '../../../../components/Text/Text';
import SubmitCoinCard from '../../modules/Card/Card';
import style from './Contact.module.scss';
import '../../../../scss/style.scss';

const ContactSection = ({ handleSuccess, control, errors }) => {
  return (
    <SubmitCoinCard className={style.contact} title={'Contact information'}>
      <div className={style.contactBody}>
        <Controller
          control={control}
          name='website_link'
          rules={{
            required: 'Required',
            pattern: {
              value: /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\/\w-]*)*\/?$/,
              message: 'Please enter a valid website URL',
            },
          }}
          render={({ field: { onChange, name, value } }) => {
            return (
              <>
                <Input
                  onChange={onChange}
                  value={value}
                  border
                  big
                  placeholder={'Website link '}
                  icon={
                    <svg
                      width='16'
                      height='16'
                      viewBox='0 0 16 16'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g clip-path='url(#clip0_14_8180)'>
                        <path
                          d='M1.33325 7.99967H14.6666M1.33325 7.99967C1.33325 11.6816 4.31802 14.6663 7.99992 14.6663M1.33325 7.99967C1.33325 4.31778 4.31802 1.33301 7.99992 1.33301M14.6666 7.99967C14.6666 11.6816 11.6818 14.6663 7.99992 14.6663M14.6666 7.99967C14.6666 4.31778 11.6818 1.33301 7.99992 1.33301M7.99992 1.33301C9.66744 3.15858 10.6151 5.5277 10.6666 7.99967C10.6151 10.4717 9.66744 12.8408 7.99992 14.6663M7.99992 1.33301C6.3324 3.15858 5.38475 5.5277 5.33325 7.99967C5.38475 10.4717 6.3324 12.8408 7.99992 14.6663'
                          stroke='white'
                          stroke-opacity='0.5'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_14_8180'>
                          <rect width='16' height='16' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                  }
                />
                {errors?.website_link && (
                  <label className='error-message'>
                    {errors?.website_link?.message}
                  </label>
                )}
              </>
            );
          }}
        />
        <Controller
          control={control}
          name='email'
          rules={{
            required: 'Required',
            pattern: {
              value: /^\s*[A-ZA-z0-9._%+-]+@[A-Za-z0-9.-]+[A-Za-z]{2,}\s*$/i,
              message: 'Invalid email address',
            },
          }}
          render={({ field: { onChange, name, value } }) => {
            return (
              <>
                <Input
                  onChange={onChange}
                  value={value}
                  border
                  big
                  placeholder={'Email '}
                  icon={
                    <svg
                      width='16'
                      height='16'
                      viewBox='0 0 16 16'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M1.33325 4.66699L4.79581 6.97537C4.99558 7.10854 5.09546 7.17513 5.20352 7.2223C5.29943 7.26417 5.39994 7.2946 5.50297 7.31297C5.61905 7.33366 5.73909 7.33366 5.97917 7.33366H10.0207C10.2607 7.33366 10.3808 7.33366 10.4969 7.31297C10.5999 7.2946 10.7004 7.26417 10.7963 7.2223C10.9044 7.17513 11.0043 7.10854 11.204 6.97537L14.6666 4.66699M4.53325 13.3337H11.4666C12.5867 13.3337 13.1467 13.3337 13.5746 13.1157C13.9509 12.9239 14.2569 12.618 14.4486 12.2416C14.6666 11.8138 14.6666 11.2538 14.6666 10.1337V5.86699C14.6666 4.74689 14.6666 4.18683 14.4486 3.75901C14.2569 3.38269 13.9509 3.07673 13.5746 2.88498C13.1467 2.66699 12.5867 2.66699 11.4666 2.66699H4.53325C3.41315 2.66699 2.85309 2.66699 2.42527 2.88498C2.04895 3.07673 1.74299 3.38269 1.55124 3.75901C1.33325 4.18683 1.33325 4.74689 1.33325 5.86699V10.1337C1.33325 11.2538 1.33325 11.8138 1.55124 12.2416C1.74299 12.618 2.04895 12.9239 2.42527 13.1157C2.85309 13.3337 3.41315 13.3337 4.53325 13.3337Z'
                        stroke='white'
                        stroke-opacity='0.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                  }
                />
                {errors?.email && (
                  <label className='error-message'>
                    {errors?.email?.message}
                  </label>
                )}
              </>
            );
          }}
        />
        <Controller
          control={control}
          name='telegram'
          rules={{
            required: 'Required',
            pattern: {
              value:
                /(https?:\/\/)?(www[.])?(telegram|t)\.me\/([a-zA-Z0-9_-]*)\/?$/,
              message:
                'Please enter a valid Telegram link (e.g., https://t.me/username)',
            },
          }}
          render={({ field: { onChange, name, value } }) => {
            return (
              <>
                <Input
                  onChange={onChange}
                  value={value}
                  border
                  big
                  placeholder={'Telegram '}
                  icon={
                    <svg
                      width='16'
                      height='16'
                      viewBox='0 0 16 16'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M2.27572 7.16299C1.87882 7.00864 1.68037 6.93146 1.62243 6.82026C1.57221 6.72386 1.57214 6.60903 1.62225 6.51257C1.68006 6.4013 1.87842 6.32389 2.27513 6.16908L13.5336 1.77552C13.8918 1.63576 14.0708 1.56588 14.1852 1.60411C14.2846 1.6373 14.3626 1.71528 14.3958 1.81465C14.434 1.92907 14.3641 2.10813 14.2244 2.46625L9.8308 13.7247C9.67599 14.1215 9.59858 14.3198 9.48731 14.3776C9.39085 14.4277 9.27602 14.4277 9.17962 14.3774C9.06841 14.3195 8.99124 14.1211 8.83689 13.7242L7.08482 9.21883C7.05349 9.13826 7.03782 9.09798 7.01362 9.06406C6.99218 9.03399 6.96589 9.0077 6.93582 8.98625C6.9019 8.96206 6.86162 8.94639 6.78105 8.91506L2.27572 7.16299Z'
                        stroke='white'
                        stroke-opacity='0.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                  }
                />
                {errors?.telegram && (
                  <label className='error-message'>
                    {errors?.telegram?.message}
                  </label>
                )}
              </>
            );
          }}
        />
        <Text grey center className={style.contactBody__divider}>
          Optional link
        </Text>
        <Controller
          control={control}
          name='twitter'
          rules={{
            required: false,
          }}
          render={({ field: { onChange, name, value } }) => {
            return (
              <Input
                onChange={onChange}
                value={value}
                border
                big
                placeholder={'Twitter '}
                icon={
                  <svg
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M1.33325 4.66699L4.79581 6.97537C4.99558 7.10854 5.09546 7.17513 5.20352 7.2223C5.29943 7.26417 5.39994 7.2946 5.50297 7.31297C5.61905 7.33366 5.73909 7.33366 5.97917 7.33366H10.0207C10.2607 7.33366 10.3808 7.33366 10.4969 7.31297C10.5999 7.2946 10.7004 7.26417 10.7963 7.2223C10.9044 7.17513 11.0043 7.10854 11.204 6.97537L14.6666 4.66699M4.53325 13.3337H11.4666C12.5867 13.3337 13.1467 13.3337 13.5746 13.1157C13.9509 12.9239 14.2569 12.618 14.4486 12.2416C14.6666 11.8138 14.6666 11.2538 14.6666 10.1337V5.86699C14.6666 4.74689 14.6666 4.18683 14.4486 3.75901C14.2569 3.38269 13.9509 3.07673 13.5746 2.88498C13.1467 2.66699 12.5867 2.66699 11.4666 2.66699H4.53325C3.41315 2.66699 2.85309 2.66699 2.42527 2.88498C2.04895 3.07673 1.74299 3.38269 1.55124 3.75901C1.33325 4.18683 1.33325 4.74689 1.33325 5.86699V10.1337C1.33325 11.2538 1.33325 11.8138 1.55124 12.2416C1.74299 12.618 2.04895 12.9239 2.42527 13.1157C2.85309 13.3337 3.41315 13.3337 4.53325 13.3337Z'
                      stroke='white'
                      stroke-opacity='0.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                }
              />
            );
          }}
        />
        <Controller
          control={control}
          name='discord'
          rules={{
            required: false,
          }}
          render={({ field: { onChange, name, value } }) => {
            return (
              <Input
                onChange={onChange}
                value={value}
                border
                big
                placeholder={'Discord '}
                icon={
                  <svg
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M12.1692 4.13029C11.3917 3.7665 10.5603 3.50211 9.6911 3.35156C9.58435 3.54456 9.45964 3.80414 9.37366 4.01063C8.44969 3.87168 7.53422 3.87168 6.62724 4.01063C6.54127 3.80414 6.41373 3.54456 6.30603 3.35156C5.4359 3.50211 4.60356 3.76747 3.82602 4.13221C2.25772 6.50213 1.83258 8.81318 2.04515 11.0914C3.08533 11.8682 4.09339 12.3401 5.08444 12.6488C5.32914 12.3121 5.54737 11.9541 5.73538 11.5768C5.37732 11.4407 5.03437 11.2728 4.71033 11.0779C4.79629 11.0142 4.88038 10.9476 4.96163 10.8791C6.93806 11.8036 9.08551 11.8036 11.0383 10.8791C11.1205 10.9476 11.2046 11.0142 11.2896 11.0779C10.9646 11.2738 10.6207 11.4417 10.2627 11.5778C10.4507 11.9541 10.668 12.313 10.9136 12.6498C11.9056 12.341 12.9146 11.8692 13.9548 11.0914C14.2042 8.45036 13.5287 6.16053 12.1692 4.13029ZM6.00464 9.69032C5.41134 9.69032 4.92478 9.13643 4.92478 8.46193C4.92478 7.78744 5.40095 7.23259 6.00464 7.23259C6.60835 7.23259 7.0949 7.78646 7.08451 8.46193C7.08544 9.13643 6.60835 9.69032 6.00464 9.69032ZM9.99532 9.69032C9.40201 9.69032 8.91545 9.13643 8.91545 8.46193C8.91545 7.78744 9.3916 7.23259 9.99532 7.23259C10.599 7.23259 11.0856 7.78646 11.0752 8.46193C11.0752 9.13643 10.599 9.69032 9.99532 9.69032Z'
                      fill='white'
                      fill-opacity='0.5'
                    />
                  </svg>
                }
              />
            );
          }}
        />
        <Controller
          control={control}
          name='raddit'
          rules={{
            required: false,
          }}
          render={({ field: { onChange, name, value } }) => {
            return (
              <Input
                onChange={onChange}
                value={value}
                border
                big
                placeholder={'Raddit '}
                icon={
                  <svg
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M14.9998 8.31754C14.9684 7.47103 14.2578 6.81002 13.4086 6.83876C13.0324 6.85183 12.6745 7.00597 12.4028 7.26463C11.2062 6.44947 9.80054 6.00271 8.35573 5.97135L9.03764 2.69245L11.2872 3.16534C11.3499 3.74274 11.8672 4.16077 12.4446 4.09807C13.022 4.03536 13.44 3.51805 13.3773 2.94065C13.3146 2.36325 12.7973 1.94523 12.2199 2.00793C11.8881 2.04189 11.5902 2.23523 11.4256 2.52263L8.84953 2.00793C8.67448 1.96874 8.49943 2.07847 8.46024 2.25613C8.46024 2.25875 8.46024 2.25875 8.46024 2.26136L7.68427 5.90865C6.22118 5.93216 4.79727 6.38154 3.58499 7.20192C2.9684 6.62191 1.99649 6.65065 1.41647 7.26985C0.836458 7.88644 0.865198 8.85836 1.4844 9.43837C1.60458 9.55072 1.74306 9.64739 1.8972 9.71532C1.88675 9.86946 1.88675 10.0236 1.8972 10.1778C1.8972 12.5318 4.64051 14.4469 8.02392 14.4469C11.4073 14.4469 14.1506 12.5344 14.1506 10.1778C14.1611 10.0236 14.1611 9.86946 14.1506 9.71532C14.6784 9.45144 15.0102 8.908 14.9998 8.31754ZM4.48898 9.37044C4.48898 8.79043 4.96187 8.31754 5.54188 8.31754C6.1219 8.31754 6.59479 8.79043 6.59479 9.37044C6.59479 9.95046 6.1219 10.4234 5.54188 10.4234C4.95926 10.4181 4.48898 9.95046 4.48898 9.37044ZM10.5948 12.2601C9.84757 12.8218 8.93313 13.1092 7.9978 13.07C7.06246 13.1092 6.14802 12.8218 5.4008 12.2601C5.30152 12.1399 5.31981 11.9596 5.43999 11.8603C5.5445 11.7741 5.69342 11.7741 5.80054 11.8603C6.4328 12.3228 7.20354 12.5579 7.98735 12.5213C8.77115 12.5631 9.5445 12.3384 10.1846 11.8812C10.2996 11.7689 10.4877 11.7715 10.6 11.8864C10.7124 12.0014 10.7098 12.1895 10.5948 12.3019V12.2601ZM10.4067 10.4599C9.82667 10.4599 9.35377 9.98703 9.35377 9.40702C9.35377 8.82701 9.82667 8.35411 10.4067 8.35411C10.9867 8.35411 11.4596 8.82701 11.4596 9.40702C11.4831 9.98703 11.0311 10.4756 10.4511 10.4991C10.4328 10.4991 10.4171 10.4991 10.3988 10.4991L10.4067 10.4599Z'
                      fill='white'
                      fill-opacity='0.5'
                    />
                  </svg>
                }
              />
            );
          }}
        />
        <Controller
          control={control}
          name='github'
          rules={{
            required: false,
          }}
          render={({ field: { onChange, name, value } }) => {
            return (
              <Input
                onChange={onChange}
                value={value}
                border
                big
                placeholder={'GitHub '}
                icon={
                  <svg
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <g clip-path='url(#clip0_1149_4856)'>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M7.97616 0C3.56555 0 0 3.66667 0 8.20283C0 11.8288 2.28457 14.8982 5.45388 15.9845C5.85012 16.0662 5.99527 15.808 5.99527 15.5908C5.99527 15.4007 5.9822 14.7488 5.9822 14.0697C3.76343 14.5587 3.30139 13.0918 3.30139 13.0918C2.94482 12.1412 2.41649 11.8968 2.41649 11.8968C1.69029 11.3943 2.46939 11.3943 2.46939 11.3943C3.27494 11.4487 3.69763 12.2363 3.69763 12.2363C4.41061 13.4857 5.55951 13.1327 6.02171 12.9153C6.08767 12.3857 6.2991 12.019 6.52359 11.8153C4.75396 11.6252 2.89208 10.919 2.89208 7.76817C2.89208 6.87183 3.20882 6.1385 3.71069 5.56817C3.63151 5.3645 3.35412 4.52233 3.79004 3.39517C3.79004 3.39517 4.46351 3.17783 5.98204 4.23717C6.63218 4.05761 7.30265 3.96627 7.97616 3.9655C8.64963 3.9655 9.33616 4.06067 9.97012 4.23717C11.4888 3.17783 12.1623 3.39517 12.1623 3.39517C12.5982 4.52233 12.3207 5.3645 12.2415 5.56817C12.7566 6.1385 13.0602 6.87183 13.0602 7.76817C13.0602 10.919 11.1984 11.6115 9.41551 11.8153C9.70612 12.0733 9.9569 12.5622 9.9569 13.3363C9.9569 14.4363 9.94384 15.3192 9.94384 15.5907C9.94384 15.808 10.0891 16.0662 10.4852 15.9847C13.6545 14.898 15.9391 11.8288 15.9391 8.20283C15.9522 3.66667 12.3736 0 7.97616 0Z'
                        fill='white'
                        fill-opacity='0.5'
                      />
                    </g>
                    <defs>
                      <clipPath id='clip0_1149_4856'>
                        <rect width='16' height='16' fill='white' />
                      </clipPath>
                    </defs>
                  </svg>
                }
              />
            );
          }}
        />
        <Controller
          control={control}
          name='whitepaper'
          rules={{
            required: false,
          }}
          render={({ field: { onChange, name, value } }) => {
            return (
              <Input
                onChange={onChange}
                value={value}
                border
                big
                placeholder={'Whitepaper link '}
                icon={
                  <svg
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M9.33341 7.33301H5.33341M6.66675 9.99967H5.33341M10.6667 4.66634H5.33341M13.3334 4.53301V11.4663C13.3334 12.5864 13.3334 13.1465 13.1154 13.5743C12.9237 13.9506 12.6177 14.2566 12.2414 14.4484C11.8136 14.6663 11.2535 14.6663 10.1334 14.6663H5.86675C4.74664 14.6663 4.18659 14.6663 3.75877 14.4484C3.38244 14.2566 3.07648 13.9506 2.88473 13.5743C2.66675 13.1465 2.66675 12.5864 2.66675 11.4663V4.53301C2.66675 3.4129 2.66675 2.85285 2.88473 2.42503C3.07648 2.0487 3.38244 1.74274 3.75877 1.55099C4.18659 1.33301 4.74664 1.33301 5.86675 1.33301H10.1334C11.2535 1.33301 11.8136 1.33301 12.2414 1.55099C12.6177 1.74274 12.9237 2.0487 13.1154 2.42503C13.3334 2.85285 13.3334 3.4129 13.3334 4.53301Z'
                      stroke='white'
                      stroke-opacity='0.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                }
              />
            );
          }}
        />
      </div>
    </SubmitCoinCard>
  );
};

export default ContactSection;
