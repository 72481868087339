import { useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import Button from '../../../../components/Button/Button';
import SubmitCoinCard from '../../modules/Card/Card';
import style from './Category.module.scss';
import '../../../../scss/style.scss';

const CategorySection = ({ categories, control, errors }) => {
  return (
    <Controller
      control={control}
      name='category'
      rules={{
        required: 'Required',
        validate: (value) =>
          value.length > 0 || 'At least one category must be chosen',
      }}
      render={({ field: { onChange, value } }) => {
        return (
          <>
            <SubmitCoinCard
              className={style.category}
              label={'Choose 1 or more categories'}
              title={'Category'}
            >
              <div className={style.categoryBody}>
                {categories?.map((item) => {
                  return (
                    <CategoryItem
                      key={item?.id}
                      category={item}
                      value={value || []} // Ensure value is an array
                      onChange={onChange}
                    >
                      {item?.title}
                    </CategoryItem>
                  );
                })}
              </div>
            </SubmitCoinCard>
            {errors?.category && (
              <label className='error-message'>
                {errors?.category?.message}
              </label>
            )}
          </>
        );
      }}
    />
  );
};

// const CategoryItem = ({ children, onChange, value }) => {
//   const inputRef = useRef(null);
//   const [checked, setChecked] = useState(false);

//   const handleChecked = () => {
//     setChecked(inputRef.current.checked);
//     onChange(inputRef.current.checked)
//   };

//   return (
//     <label className={style.categoryItem__wrapper}>
//       <input onChange={handleChecked} ref={inputRef} type="checkbox" value={value}/>
//       <Button isDiv filled={checked} className={style.categoryBody__item} sm>
//         {children}
//       </Button>
//     </label>
//   );
// };

const CategoryItem = ({ children, category, onChange, value }) => {
  const inputRef = useRef(null);

  const isChecked = value.includes(category.id);

  const handleChecked = () => {
    let newSelectedCategories;

    if (isChecked) {
      newSelectedCategories = value.filter((id) => id !== category.id);
    } else {
      newSelectedCategories = [...value, category.id];
    }

    onChange(newSelectedCategories);
  };

  return (
    <label className={style.categoryItem__wrapper}>
      <input
        type='checkbox'
        ref={inputRef}
        checked={isChecked}
        onChange={handleChecked}
      />
      <Button isDiv filled={isChecked} className={style.categoryBody__item} sm>
        {children}
      </Button>
    </label>
  );
};

export default CategorySection;
