import { useState } from 'react';
import Button from '../../../../components/Button/Button';
import { useUploadImageMutation } from '../../../../store/coinsApi';
import style from './Banner.module.scss';
import "../../../../scss/style.scss";

const Banner = ({ img, coinId }) => {
  const [uploadImgReq, {data: upadImgResp}] = useUploadImageMutation();  
  const [formatError, setFormatErr] = useState();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file?.type?.includes("png")) {
      const formData = new FormData();
      formData.append(`image`, file);
      uploadImgReq({
        formData: formData,
        type: 'coin-banner',
        coin_id: coinId,
      });
    } else {
      setFormatErr("Only .png format is allowed")
    }
  };

  console.log(upadImgResp, "upadImgResp");
  

  return (
    <div className={`${style.con} __container`}>
      <div className={style.banner}>
        <div className={style.banner__img}>
          <img src={upadImgResp?.image ? upadImgResp?.image : img} alt='' />
        </div>
        <label htmlFor='fileInput'>
          <Button filledGrey className={style.banner__add}>
            Add Custom Banner
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M7.99992 3.33203V12.6654M3.33325 7.9987H12.6666'
                stroke='white'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            <input
              type='file'
              id='fileInput'
              style={{ position: 'absolute', opacity: '0' }}
              onChange={handleFileChange}
            />
          </Button>
        </label>
      </div>
      {formatError && (
        <label className='error-message'>{formatError}</label>
      )}
    </div>
  );
};

export default Banner;
