import style from './index.module.scss';
import PersonalSection from './sections/Personal/Personal';
import ProjectsSection from './sections/Projects/Projects';

const StatisticPage = () => {
  return (
    <div className={style.statistic}>
      <div className='__container'>
        <PersonalSection />
        <ProjectsSection />
      </div>
    </div>
  );
};

export default StatisticPage;
