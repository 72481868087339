import Text from '../Text/Text';
import Title from '../Title/Title';
import style from './MobilePageHeading.module.scss';

const MobilePageHeading = ({ title, text }) => {
  return (
    <div className={style.heading}>
      {/* <div className='__container'> */}
      <div className={style.headingBody}>
        <div className={style.headingBody__top}>
          <button>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M20 12H4M4 12L10 18M4 12L10 6'
                stroke='white'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          </button>
          <Title.H1 fz42>{title}</Title.H1>
        </div>
        <Text grey className={style.headingBody__text}>
          {text}
        </Text>
      </div>
      {/* </div> */}
    </div>
  );
};

export default MobilePageHeading;
