import { useEffect, useRef, useState } from 'react';
import Button from '../../Button/Button';
import Input from '../../Input/Input';
import ModalWrapper from '../../ModalWrapper/ModalWrapper';
import Select from '../../Select/Select';
import Text from '../../Text/Text';
import Textarea from '../../Textarea/Textarea';
import Title from '../../Title/Title';
import UploadFile from '../../UploadFile/UploadFile';
import style from './Verify.module.scss';
import icon from './assets/icon.svg';
import icon2 from './assets/icon2.svg';
import { useVerifyEmailQuery } from '../../../store/authApi';

const VerifyModal = ({ close }) => {
  const [isSwitched, setIsSwitched] = useState(false);
  const codeInputRef = useRef(null);
  const [code, setCode] = useState('');

  const handleCode = ({ target }) => {
    setCode(target.value);
  };

  // const {verifyEmailReq} = useVerifyEmailQuery()

  return (
    <ModalWrapper closeModal={close} withBack={!isSwitched}>
      <div className={style.modal}>
        {isSwitched ? (
          <>
            <div className={style.modal__icon}>
              <img src={icon2} alt='' />
            </div>
            <Title.H1 center className={style.modal__title}>
              Congratulation
            </Title.H1>
            <Text grey center>
              You have successfully registered on our site, have <br /> a
              successful investment in our projects
            </Text>
            <div className={style.modalBody}>
              <Button className={style.button__spaceBig} filled>
                Explore Market
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className={style.modal__icon}>
              <img src={icon} alt='' />
            </div>
            <Title.H1 center className={style.modal__title}>
              Verify Your Email
            </Title.H1>
            <Text grey center>
              We’ve sent an email to example@gmail.com with a link to verify
              your email. You may click the button in the email or enter the
              verification code below.
            </Text>
            <div className={style.modalBody}>
              <label className={style.modalBody__code}>
                <input
                  maxLength={5}
                  ref={codeInputRef}
                  onChange={handleCode}
                  type='password'
                />
                <Button isDiv className={style.modalBody__codeItem} onlyIcon>
                  {/* {code[0] && code[0]} */}
                  {code[0] && (
                    <svg
                      width='8'
                      height='8'
                      viewBox='0 0 8 8'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect width='8' height='8' rx='3' fill='white' />
                    </svg>
                  )}
                </Button>
                <Button isDiv className={style.modalBody__codeItem} onlyIcon>
                  {/* {code[1] && code[1]} */}
                  {code[1] && (
                    <svg
                      width='8'
                      height='8'
                      viewBox='0 0 8 8'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect width='8' height='8' rx='3' fill='white' />
                    </svg>
                  )}
                </Button>
                <Button isDiv className={style.modalBody__codeItem} onlyIcon>
                  {/* {code[2] && code[2]} */}
                  {code[2] && (
                    <svg
                      width='8'
                      height='8'
                      viewBox='0 0 8 8'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect width='8' height='8' rx='3' fill='white' />
                    </svg>
                  )}
                </Button>
                <Button isDiv className={style.modalBody__codeItem} onlyIcon>
                  {/* {code[3] && code[3]} */}
                  {code[3] && (
                    <svg
                      width='8'
                      height='8'
                      viewBox='0 0 8 8'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect width='8' height='8' rx='3' fill='white' />
                    </svg>
                  )}
                </Button>
                <Button isDiv className={style.modalBody__codeItem} onlyIcon>
                  {/* {code[4] && code[4]} */}
                  {code[4] && (
                    <svg
                      width='8'
                      height='8'
                      viewBox='0 0 8 8'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect width='8' height='8' rx='3' fill='white' />
                    </svg>
                  )}
                </Button>
              </label>
              <Button
                onClick={() => setIsSwitched(true)}
                className={style.button__space}
                filled
              >
                Resend
              </Button>
              {/* <div className={style.loader}>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M23.4 12C23.7314 12 24.0016 11.7312 23.985 11.4002C23.9164 10.0293 23.613 8.67872 23.0866 7.4078C22.4835 5.95189 21.5996 4.62902 20.4853 3.51472C19.371 2.40042 18.0481 1.5165 16.5922 0.913445C15.3213 0.387013 13.9707 0.0836007 12.5998 0.0149965C12.2688 -0.00156491 12 0.268629 12 0.6C12 0.931371 12.2688 1.19826 12.5997 1.21666C13.813 1.28414 15.0077 1.55598 16.133 2.0221C17.4433 2.56485 18.6339 3.36037 19.6368 4.36325C20.6396 5.36612 21.4351 6.5567 21.9779 7.86702C22.444 8.99233 22.7159 10.187 22.7833 11.4003C22.8017 11.7312 23.0686 12 23.4 12Z'
                    fill='white'
                  />
                </svg>
              </div> */}
            </div>
          </>
        )}
      </div>
    </ModalWrapper>
  );
};

export default VerifyModal;
