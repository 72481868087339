import { useCallback, useEffect, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  useCreateCoinMutation,
  useEditCoinMutation,
  useGetAllCoinsQuery,
  useGetCoinByIdQuery,
  useGetEditCoinInfoQuery,
} from '../../store/coinsApi';
import UseGeneralHooks from '../../hooks/useGeneralHooks';
import { useNavigate } from 'react-router-dom';

const UseSubmitCoinHooks = () => {
  const { data: createCoinPageData } = useGetAllCoinsQuery();
  const { params } = UseGeneralHooks();

  const { data: coinByIdInfo } = useGetCoinByIdQuery(params?.id, {
    skip: !params?.id,
  });

  const { data: editCoinInfo } = useGetEditCoinInfoQuery(params?.id, {
    skip: !params?.id,
  });

  const transformedOptions = useMemo(() => {
    return createCoinPageData?.networks?.map((option) => ({
      name: option.title,
      value: option.id,
    }));
  }, [createCoinPageData?.networks]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
    setValue,
    setError,
    getValues,
  } = useForm({
    defaultValues: {
      about: {
        img: editCoinInfo?.form?.about?.img?.url && null,
      },
    },
  });

  useEffect(() => {
    if (transformedOptions && editCoinInfo) {
      setValue('name', editCoinInfo?.form?.name);
      setValue('symbol', editCoinInfo?.form?.symbol);
      setValue('category', editCoinInfo?.form?.category);
      setValue('presale_link', editCoinInfo?.form?.about?.presale_link);
      setValue('contract_address', editCoinInfo?.form?.about?.contract_address);
      setValue('description', editCoinInfo?.form?.about?.description);
      setValue('youtube_link', editCoinInfo?.form?.about?.youtube_link?.link);
      setValue(
        'coingecko_link',
        editCoinInfo?.form?.about?.coingecko_link?.link
      );
      setValue(
        'pancakeswap_link',
        editCoinInfo?.form?.about?.pancakeswap_link?.link
      );
      setValue('poocoin_link', editCoinInfo?.form?.about?.poocoin_link?.link);
      setValue(
        'website_link',
        editCoinInfo?.form?.contact_info?.website_link?.link
      );
      setValue('email', editCoinInfo?.form?.contact_info?.email);
      setValue('telegram', editCoinInfo?.form?.contact_info?.telegram?.link);
      setValue('twitter', editCoinInfo?.form?.contact_info?.twitter?.link);
      setValue('discord', editCoinInfo?.form?.contact_info?.discord?.link);
      setValue('raddit', editCoinInfo?.form?.contact_info?.reddit?.link);
      setValue('github', editCoinInfo?.form?.contact_info?.github?.link);
      setValue(
        'whitepaper',
        editCoinInfo?.form?.contact_info?.whitepaper?.link
      );
      setValue('faq', editCoinInfo?.form?.faq);
      setValue('fare_launch', 'yes');
      setValue(
        'launch_date_is_known',
        editCoinInfo?.form?.about?.launch_date_is_known ? 'yes' : 'no'
      );
      setValue(
        'custom_chart_link',
        editCoinInfo?.form?.about?.custom_chart_link
      );
      setValue('custom_swap_link', editCoinInfo?.form?.about?.custom_swap_link);
      setValue('audits', editCoinInfo?.form?.audits);
      setValue('airdrops', editCoinInfo?.form?.airdrops);
      setValue(
        'kycCertificates',
        editCoinInfo?.form?.kycCertificates?.map((item) => {
          return {
            link: item?.link,
            comment: item?.comment,
          };
        })
      );
    }
  }, [transformedOptions, editCoinInfo]);

  const {
    fields: faqFields,
    append,
    remove: removeFaq,
  } = useFieldArray({
    control,
    name: 'faq',
  });

  const {
    fields: kycCertificateFields,
    append: appendNewKYCCertificate,
    remove: removeKYCCertificate,
  } = useFieldArray({
    control,
    name: 'kycCertificates',
  });

  const {
    fields: roadmapFields,
    append: appendNewRoadmap,
    remove: removeRoadmap,
  } = useFieldArray({
    control,
    name: 'roadmaps',
  });

  const {
    fields: auditFields,
    append: appendNewAudit,
    remove: removeAudit,
  } = useFieldArray({
    control,
    name: 'audits',
  });

  const addNewFaq = useCallback(() => {
    append({ question: '', answer: '' });
  }, [append]);

  const addNewKYCCertificate = useCallback(() => {
    appendNewKYCCertificate({ link: '', comment: '' });
  }, [appendNewKYCCertificate]);

  const addNewRoadmap = useCallback(() => {
    appendNewRoadmap({ step: '' });
  }, [appendNewRoadmap]);

  const addNewAudit = useCallback(() => {
    appendNewAudit({ link: '', comment: '' });
  }, [appendNewAudit]);

  const [submitCoinReq, { isSuccess, isLoading }] = useCreateCoinMutation();
  const [editCoinReq] = useEditCoinMutation();

  const onSubmit = (data) => {
    const payload = {
      name: data?.name,
      ...(data?.symbol && { symbol: data?.symbol }),
      platform: data?.platform?.value,
      category: data?.category,
      about: {
        img: data?.img,
        contract_address: data?.contract_address,
        description: data?.description,
        youtube_link: data?.youtube_link,
        coingecko_link: data?.coingecko_link,
        pancakeswap_link: data?.pancakeswap_link,
        poocoin_link: data?.poocoin_link,
        presale_link: data?.presale_link,
        fare_launch: data?.fare_launch,
        softcap: data?.softcap,
        softcap_platform: data?.softcap_platform?.value,
        softcap_custom: data?.softcap_custom,
        presale_start: {
          time: +data?.start_time?.value,
          day: +data?.start_day?.value,
          month: data?.start_month?.value,
          year: +data?.start_year?.value,
        },
        presale_end: {
          time: +data?.end_time?.value,
          day: +data?.end_day?.value,
          month: data?.end_month?.value,
          year: +data?.end_year?.value,
        },
        launch_date_is_known:
          data?.launch_date_is_known === 'yes' ? true : false,
        launch_date:
          watch('launch_date_is_known') === 'yes'
            ? {
                launch_time: +data?.launch_time?.value,
                launch_day: +data?.launch_day?.value,
                launch_month: data?.launch_month?.value,
                launch_year: +data?.launch_year?.value,
              }
            : null,
        custom_chart_link: data?.custom_chart_link,
        custom_swap_link: data?.custom_swap_link,
      },
      contact_info: {
        website_link: data?.website_link,
        email: data?.email,
        telegram: data?.telegram,
        twitter: data?.twitter && data?.twitter,
        discord: data?.discord && data?.discord,
        reddit: data?.raddit && data?.raddit,
        github: data?.github && data?.github,
        whitepaper: data?.whitepaper && data?.whitepaper,
      },
      faq: data?.faq,
      kycCertificates: data?.kycCertificates,
      roadmaps: data?.roadmaps?.length > 1 ? data?.roadmaps : null,
      audits: data?.audits,
      airdrops: data?.airdrops,
      ...(data?.tokenomics && {
        tokenomics: {
          tokenomics_softcap: data?.tokenomics_softcap,
          tokenomics_hardcap: data?.tokenomics_hardcap,
          tokenomics_presale_start: {
            time: +data?.tokenomics_start_time?.value,
            day: +data?.tokenomics_start_day?.value,
            month: data?.tokenomics_start_month?.value,
            year: +data?.tokenomics_start_year?.value,
          },
          tokenomics_presale_end: {
            time: +data?.tokenomics_end_time?.value,
            day: +data?.tokenomics_end_day?.value,
            month: data?.tokenomics_end_month?.value,
            year: +data?.tokenomics_end_year?.value,
          },
          presalePercent: data?.presalePercent,
          treasuryPercent: data?.treasuryPercent,
          communityPercent: data?.communityPercent,
          liquidityPercent: data?.liquidityPercent,
          marketingPercent: data?.marketingPercent,
          developmentPercent: data?.developmentPercent,
          verificationLink: data?.verificationLink,
        },
      }),
    };

    if (params?.id) {
      editCoinReq({ coin_id: params?.id, payload });
    } else {
      submitCoinReq(payload);
    }
  };

  const navigate = useNavigate();
  console.log(errors, 'errors');

  useEffect(() => {
    if (isSuccess) {
      reset({});
      if (params?.id) {
        navigate(`/coins/${params?.id}`);
      }
    }
  }, [isSuccess]);

  return {
    kycCertificateFields,
    createCoinPageData,
    roadmapFields,
    coinByIdInfo,
    auditFields,
    removeFaq,
    faqFields,
    isLoading,
    control,
    params,
    errors,
    watch,
    reset,
    onSubmit,
    setError,
    setValue,
    addNewFaq,
    addNewAudit,
    removeAudit,
    handleSubmit,
    addNewRoadmap,
    removeRoadmap,
    removeKYCCertificate,
    addNewKYCCertificate,
  };
};

export default UseSubmitCoinHooks;
