import ResultRow from './compontents/Row/Row';
import style from './Results.module.scss';
import defaultImg from "../../modules/Slider/assets/logo.jpg";


const SearchResults = ({ list }) => {  

  return (
    <div className={style.results}>
      <div className={style.resultsContent}>
        {list?.map((item, index) => (
          <ResultRow
            key={index}
            img={item.image ? item?.image : defaultImg}
            name={item.title}
            label={item.symbol}
            address={item.address}
            rate={item.votes_count}
          />
        ))}
      </div>
    </div>
  );
};

export default SearchResults;
