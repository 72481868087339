import { useEffect, useState } from 'react';
import Text from '../../../../components/Text/Text';
import Title from '../../../../components/Title/Title';
import style from './PanelCard.module.scss';

import presaleIcon from '../../../../assets/btn_icons/presale.svg';
import boostIcon from '../../../../assets/btn_icons/boost.svg';
import Countdown from 'react-countdown';

const PanelCard = ({
  yellow,
  blue,
  blue2,
  red,
  grey,
  green,
  pink,
  title,
  btnText = 'Show All',
  children,
  className,
}) => {
  return (
    <div
      className={`${style.card} 
    ${yellow && style.yellow}
    ${blue && style.blue}
    ${blue2 && style.blue2}
    ${grey && style.grey}
    ${green && style.green}
    ${red && style.red}
    ${pink && style.pink}
    ${className}
    `}
    >
      <div className={style.cardTop}>
        <Title.H2 className={style.cardTop__title}>{title}</Title.H2>
        <Text className={style.cardTop__btn}>{btnText}</Text>
      </div>
      <div className={style.cardContent}>{children}</div>
    </div>
  );
};

export const PanelCardRow = ({ children }) => {
  return <div className={style.row}>{children}</div>;
};

export const PanelCardNews = ({ img, title, text }) => {
  return (
    <div className={style.news}>
      <div className={style.news__img}>
        <img src={img} alt='' />
      </div>
      <div className={style.newsContent}>
        <Text semiBold className={style.newsContent__title}>
          {title}
        </Text>
        <Text grey className={style.newsContent__text}>
          {text}
        </Text>
      </div>
    </div>
  );
};

export const PanelCardTags = ({ tags }) => {
  return (
    <div className={style.tags}>
      {tags.map((item, index) => (
        <span key={index}>
          <Text grey>{item}</Text>
        </span>
      ))}
    </div>
  );
};

export const PanelCardValue = ({ value, timestamp }) => {
  const [valueType, setValueType] = useState('');

  useEffect(() => {
    if (value) {
      switch (value.toLowerCase()) {
        case 'presale':
          setValueType('presale');
          break;

        default:
          setValueType('scores');
          break;
      }

      if (value.toLowerCase().includes('$')) {
        setValueType('price');
      }
    }
  }, [value]);

  return (
    <div className={style.value}>
      {valueType === 'price' && (
        <>
          <Text grey>{value}</Text>
        </>
      )}
      {valueType === 'scores' && (
        <>
          <Text grey>{value}</Text>
          <img src={boostIcon} alt='' />
        </>
      )}
      {valueType === 'presale' && (
        <>
          <Text grey>{value}</Text>
          <img src={presaleIcon} alt='' />
        </>
      )}
      {timestamp && (
        <Countdown date={Date.now() + 500000000} renderer={renderer} />
      )}
    </div>
  );
};

const Completionist = () => <span>Finish!</span>;

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <div className={style.timer}>
        <Text className={style.timer__item}>{days}d</Text>:
        <Text className={style.timer__item}>{hours}h</Text>:
        <Text className={style.timer__item}>{minutes}m</Text>
      </div>
    );
  }
};

export default PanelCard;
