import Countdown from 'react-countdown';
import Text from '../Text/Text';
import style from './Timer.module.scss';

const Timer = ({ deadline, className }) => {
  
  return (
    <div className={className}>
      <Countdown date={Date.now(deadline) + 500000000} renderer={renderer} />
    </div>
  );
};

const Completionist = () => <span>Finish!</span>;

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <Completionist />;
  } else {
    return (
      <div className={style.timer}>
        <Text className={style.timer__item}>{days}d</Text>
        <Text className={style.timer__item}>{hours}h</Text>
        <Text className={style.timer__item}>{minutes}m</Text>
      </div>
    );
  }
};

export default Timer;
