import { Swiper, SwiperSlide } from 'swiper/react';
import style from './Top.module.scss';
import Button from '../../../../components/Button/Button';
import Title from '../../../../components/Title/Title';

const AirdropsTop = ({setCategory, categories, category}) => {  

  return (
    <div className={style.top}>
      <Title.H1>Airdrops</Title.H1>
      <Swiper
        className={style.top__tabs}
        spaceBetween={8}
        slidesPerView={'auto'}
      >
        {
          categories?.map((item, index) => {
            return(
              <SwiperSlide key={index}>
              <Button filled={category === item?.id} onClick={() => setCategory(item?.id)}>{item?.title}</Button>
            </SwiperSlide>
            )
          })
        }
      </Swiper>
    </div>
  );
};

export default AirdropsTop;

