import style from './Top.module.scss';
import { useMediaQuery } from 'usehooks-ts';
import Button from '../../../../components/Button/Button';
import Text from '../../../../components/Text/Text';
import Title from '../../../../components/Title/Title';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link, useLocation } from 'react-router-dom';

import 'swiper/css';

const AdvertiseTop = ({setActiveCategory, activeCategory}) => {
  const isMobile = useMediaQuery('(max-width:991px)');
  const location = useLocation();

  return (
    <div className={style.top}>
      <div className={style.topContent}>
        <Title.H1>Advertise</Title.H1>
        {isMobile && (
          <div className={style.topBody__read}>
            <Text grey>What is Boost?</Text>
            <a href=''>
              <Text>Read now</Text>
            </a>
          </div>
        )}
      </div>
      <div className={style.topBody}>
        <Swiper
          className={style.topBody__btns}
          spaceBetween={8}
          slidesPerView={'auto'}
        >
          <SwiperSlide>
            <Link to={'new'}>
              <Button
                filled={activeCategory === 'new'}
                onClick={() => setActiveCategory("new")}
              >
                New Promotion
              </Button>
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to={'boosted'}>
              <Button
                filled={activeCategory === 'boosted'}
                onClick={() => setActiveCategory("boosted")}
              >
                Active Ads
              </Button>
            </Link>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default AdvertiseTop;
