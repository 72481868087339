import { Swiper, SwiperSlide } from 'swiper/react';
import style from './Promoted.module.scss';
import { Navigation } from 'swiper/modules';

import './Slider.scss';
import 'swiper/css';
import PromotedCard from '../../components/PromotedCard/PromotedCard';

const Promoted = ({ cards, className }) => {
  return (
    <Swiper
      modules={[Navigation]}
      navigation
      slidesPerView={'auto'}
      spaceBetween={16}
      className={`${style.promoted} ${className} promoted-slider`}
    >
      {cards.map((item, index) => (
        <SwiperSlide key={index} className={style.promoted__slide}>
          <PromotedCard img={item.img} name={item.name} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Promoted;
