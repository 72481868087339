import Text from '../Text/Text';
import style from './Notification.module.scss';

const Notification = ({ icon, text }) => {
  return (
    <div className={style.notification}>
      <div className={style.notification__icon}>
        <img src={icon} alt='' />
      </div>
      <Text grey fz14>
        {text}
      </Text>
    </div>
  );
};

export default Notification;
