import { useState } from 'react';
import ModalWrapper from '../../ModalWrapper/ModalWrapper';
import Text from '../../Text/Text';
import Title from '../../Title/Title';
import style from './Recovery.module.scss';
import icon from './assets/icon.svg';
import ForgetPassword from '../../Forms/forgetPasswordForm';
import ResetPasswordForm from '../../Forms/resetPasswordForm';

const RecoveryModal = ({ close, defaultState = false }) => {
  const [isSwitched, setIsSwitched] = useState(defaultState);
  const [email, setEmail] = useState('');

  console.log(email, 'emailemailemail');

  return (
    <ModalWrapper closeModal={close} withBack={!isSwitched}>
      <div className={style.modal}>
        {isSwitched ? (
          <>
            <Title.H1 center className={style.modal__title}>
              Create new password
            </Title.H1>
            <Text grey center>
              Use this form below to reset <br /> your password
            </Text>
            <ResetPasswordForm />
          </>
        ) : (
          <>
            <div className={style.modal__icon}>
              <img src={icon} alt='' />
            </div>
            <Title.H1 center className={style.modal__title}>
              Password recovery
            </Title.H1>
            <Text grey center>
              Enter your email address below <br /> to reset your password
            </Text>
            <ForgetPassword setIsSwitched={setIsSwitched} setEmail={setEmail} />
          </>
        )}
      </div>
    </ModalWrapper>
  );
};

export default RecoveryModal;
