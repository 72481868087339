import Calendar from '../Calendar/Calendar';
import style from './ModalWrapper.module.scss';

const ModalWrapper = ({
  isOpen,
  closeModal,
  children,
  className,
  big,
  withBack,
  withCalendar,
}) => {
  return (
    <div
      className={`${style.modal} ${big && style.big} ${
        withCalendar && style.withCalendar
      } ${isOpen && style.open}`}
    >
      <div onClick={closeModal} className={style.modal__bg}></div>
      <div className={`${style.con} __container`}>
        <div className={style.modalBody}>
          <div className={`${style.modalContent} ${className}`}>
            {withBack && (
              <div onClick={closeModal} className={style.modalContent__back}>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M20 12H4M4 12L10 18M4 12L10 6'
                    stroke='white'
                    stroke-opacity='0.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </div>
            )}
            <div onClick={closeModal} className={style.modalContent__close}>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M18 6L6 18M6 6L18 18'
                  stroke='white'
                  stroke-opacity='0.5'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            </div>
            {children}
          </div>
          {withCalendar && <Calendar className={style.modal__calendar} />}
        </div>
      </div>
    </div>
  );
};

export default ModalWrapper;
