import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './API';

export const dictionariesApi = createApi({
  reducerPath: 'dictionariesApi',
  tagTypes: ['Dictionaries'],
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    getDictionaries: builder.query({
      query: () => ({
        url: `/dictionaries`,
        method: 'GET',
      }),
      invalidatesTags: ['Dictionaries'],
    }),
  }),
});

export const { useGetDictionariesQuery, use } = dictionariesApi;
