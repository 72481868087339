import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './API';

export const reportApi = createApi({
  reducerPath: 'homeApi',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    createReport: builder.mutation({
      query: (payload) => ({
        url: `/report`,
        data: payload,
        method: 'POST',
      }),
    }),
  }),
});

export const { useCreateReportMutation } = reportApi;
