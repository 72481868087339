import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from './API';

const initialState = {
  coins: {
    list: [],
    status: 'pending',
  },
};

export const getAllCoins = createAsyncThunk(
  'coins/getAllCoins',
  async (search) => {
    const response = await API.get(`/search-coin?search=${search}`);
    return response.data;
  }
);

export const coinsSlice = createSlice({
  name: 'coins',
  initialState,
  reducers: {
    resetCoinsList(state) {
      state.coins.list = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCoins.pending, (state) => {
      state.coins.status = 'pending';
    });
    builder.addCase(getAllCoins.fulfilled, (state, action) => {
      state.coins.status = 'fulfilled';
      state.coins.list = action.payload;
    });
    builder.addCase(getAllCoins.rejected, (state) => {
      state.coins.status = 'rejected';
    });
  },
});

export const { resetCoinsList } = coinsSlice.actions;

export default coinsSlice.reducer;
