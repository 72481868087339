import { useState } from 'react';
import Text from '../../../../components/Text/Text';
import Title from '../../../../components/Title/Title';
import style from './ProgressMobile.module.scss';

const ProgressMobile = ({ className, progress = 0 }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      onClick={() => {
        !isOpen && setIsOpen(true);
      }}
      className={`${style.progress} ${isOpen && style.open} ${className}`}
    >
      <div className={style.progressBody}>
        <div style={{ '--p': progress }} className={style.progressBody__chart}>
          <Title.H2 grey={true} className={style.progressBody__chartTitle}>
            {progress}%
          </Title.H2>
        </div>
        <div className={style.progressBody__categories}>
          <ProgressCategory progress={'25%'} name={'General info'} />
          <ProgressCategory progress={'25%'} name={'Category'} />
          <ProgressCategory progress={'25%'} name={'About Project'} />
          <ProgressCategory progress={'25%'} name={'Contact Information'} />
        </div>
        <button
          onClick={() => {
            setIsOpen(false);
          }}
          className={style.progressBody__collapse}
        >
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M2.66667 9.33333H6.66667M6.66667 9.33333V13.3333M6.66667 9.33333L2 14M13.3333 6.66667H9.33333M9.33333 6.66667V2.66667M9.33333 6.66667L14 2'
              stroke='white'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

const ProgressCategory = ({ name, progress }) => {
  return (
    <div className={style.category}>
      <Text grey className={style.category__progress}>
        {progress}
      </Text>
      <Text grey={true} className={style.category__name}>
        {name}
      </Text>
    </div>
  );
};

export default ProgressMobile;
