import { Outlet } from 'react-router-dom';
import style from './index.module.scss';
import BuyBoostsTop from './modules/Top/Top';

const BoostsPage = () => {
  return (
    <div className={style.boost}>
      <div className='__container'>
        <BuyBoostsTop />
        <div className={style.boostContent}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default BoostsPage;
