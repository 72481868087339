import Title from '../../../../../../components/Title/Title';
import Select from '../../../../../../components/Select/Select';
import style from './LineGraph.module.scss';
import Chart from 'react-apexcharts';
import Text from '../../../../../../components/Text/Text';
import viewsIcon from '../../../../../../assets/btn_icons/visitors.svg';

const options = {
  chart: {
    id: 'basic-bar',
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  markers: {
    size: 0,
    colors: ['#a1d3fa'],
    strokeColors: '#202025',
    strokeWidth: 3,
  },
  tooltip: {
    enabled: true,
    theme: false,
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      return `
        <div class="${style.chatTooltip}">
          <div class="${style.chatTooltip__title}">Views</div>
          <div class="${style.chatTooltip__value}">
          ${series[seriesIndex][dataPointIndex]}
            <img src="${viewsIcon}" alt='' />
          </div>
        </div>
        `;
    },
  },
  yaxis: {
    opposite: true,
    axisBorder: {
      show: false,
    },
    min: 0,
    labels: {
      style: {
        colors: 'rgba(255, 255, 255, 0.5)',
        fontSize: '12px',
        fontFamily: 'Inter, sans-serif',
        fontWeight: 400,
        cssClass: 'apexcharts-xaxis-label',
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    tickAmount: window.innerWidth > 991 ? 10 : 5,
    crosshairs: {
      show: true,
      width: 1,
      position: 'back',
      opacity: 1,
      stroke: {
        color: '#a1d3fa',
        width: 1,
        dashArray: 7,
      },
    },
    axisBorder: {
      show: true,
      color: 'rgba(255, 255, 255, 0.1)',
      height: 1,
      width: '100%',
      offsetX: 0,
      offsetY: 0,
    },
    labels: {
      rotate: 0,
      style: {
        colors: 'rgba(255, 255, 255, 0.5)',
        fontSize: '12px',
        fontFamily: 'Inter, sans-serif',
        fontWeight: 400,
        cssClass: 'apexcharts-xaxis-label',
      },
    },
    axisTicks: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    categories: [
      '02.06',
      '03.06',
      '04.06',
      '05.06',
      '07.06',
      '08.06',
      '09.06',
      '10.06',
      '11.06',
      '12.06',
    ],
  },
  stroke: {
    width: 1,
    colors: ['#a1d3fa'],
  },
  fill: {
    type: 'gradient',
    colors: ['#A1D3FA'],
    gradient: {
      gradientToColors: '#07271F',
      opacityTo: 0.6,
    },
  },
  grid: {
    show: true,
    borderColor: 'rgba(255, 255, 255, 0.1)',
    strokeDashArray: 0,
    position: 'back',
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
};

const series = [
  {
    name: 'series-1',
    data: [60, 40, 45, 50, 49, 60, 70, 91, 85, 60],
  },
];

const LineGraph = ({ className }) => {
  return (
    <div className={`${style.chart} ${className}`}>
      <div className={style.chartTop}>
        <Title.H2 className={style.chartTop__title}>Total Views</Title.H2>
        <Select
          border
          label={'Time: '}
          options={[
            {
              name: 'Last Week',
              value: 'week',
            },
            {
              name: 'Last Day',
              value: 'day',
            },
          ]}
          className={style.chartTop__select}
        />
      </div>
      <div className={style.chart__item}>
        <Chart
          className={style.chart__item}
          options={options}
          series={series}
          width='100%'
          height={'100%'}
          type='area'
        />
      </div>
    </div>
  );
};

export default LineGraph;
