import Button from '../../Button/Button';
import Input from '../../Input/Input';
import ModalWrapper from '../../ModalWrapper/ModalWrapper';
import Text from '../../Text/Text';
import Title from '../../Title/Title';
import UploadImg from '../../UploadImg/UploadImg';
import style from './Banner.module.scss';
import icon from './assets/img.svg';
import stars from './assets/stars.svg';

const BannerModal = ({ close }) => {
  return (
    <ModalWrapper closeModal={close} withCalendar>
      <div className={style.banner}>
        <div className={style.banner__icon}>
          <img src={icon} alt='' />

          <div className={style.banner__iconStars}>
            <img src={stars} alt='' />
          </div>
        </div>
        <Title.H2>Small Banner</Title.H2>
        <Text grey center>
          {' '}
          A medium-sized banner <br /> shown on all pages.
        </Text>
        <UploadImg
          big
          transparent
          label={
            'Please upload your banner here. Format PNG, JPEG, size 200x200'
          }
          className={style.banner__upload}
        />
        <Input
          border
          big
          icon={
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g clip-path='url(#clip0_227_14526)'>
                <path
                  d='M6.66656 8.66795C6.95286 9.0507 7.31813 9.3674 7.73759 9.59657C8.15705 9.82574 8.62089 9.96202 9.09766 9.99617C9.57442 10.0303 10.0529 9.96152 10.5008 9.79447C10.9486 9.62741 11.3553 9.36599 11.6932 9.02795L13.6932 7.02795C14.3004 6.39927 14.6364 5.55726 14.6288 4.68328C14.6212 3.80929 14.2706 2.97324 13.6526 2.35522C13.0346 1.73719 12.1985 1.38663 11.3246 1.37903C10.4506 1.37144 9.60856 1.70742 8.97989 2.31461L7.83322 3.45461M9.33322 7.33461C9.04692 6.95186 8.68165 6.63516 8.26219 6.40599C7.84273 6.17681 7.37888 6.04053 6.90212 6.00639C6.42536 5.97225 5.94683 6.04103 5.49899 6.20809C5.05115 6.37515 4.64448 6.63657 4.30656 6.97461L2.30656 8.97461C1.69936 9.60329 1.36338 10.4453 1.37098 11.3193C1.37857 12.1933 1.72913 13.0293 2.34716 13.6473C2.96519 14.2654 3.80123 14.6159 4.67522 14.6235C5.54921 14.6311 6.39121 14.2951 7.01989 13.6879L8.15989 12.5479'
                  stroke='white'
                  stroke-opacity='0.5'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </g>
              <defs>
                <clipPath id='clip0_227_14526'>
                  <rect width='16' height='16' fill='white' />
                </clipPath>
              </defs>
            </svg>
          }
          placeholder={'Link to website'}
        />
        <Button className={style.banner__add} filled>
          Add to Cart
        </Button>
        <Button noBorder whiteText>
          Show position
        </Button>
      </div>
    </ModalWrapper>
  );
};

export default BannerModal;
