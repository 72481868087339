import Title from "../../../../../../components/Title/Title";
import Accordion from "../../../../../../components/Accordion/Accordion";
import style from "./Faq.module.scss";

const Faq = ({ faq }) => {
  return (
    <div className={style.faq}>
      <Title.H3 className={style.faq__title}>FAQ</Title.H3>
      <div className={style.faqBody}>
        {faq?.map((faq, index) => {
          return (
            <Accordion
              title={faq?.question}
              text={faq?.answer}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Faq;
