import style from './Transactions.module.scss';
import Table from '../../../../components/Table/Table';
import Title from '../../../../components/Title/Title';
import Button from '../../../../components/Button/Button';
import Text from '../../../../components/Text/Text';

const Transactions = ({ transactions }) => {
  console.log(transactions, 'transactions');
  return (
    <div className={style.transactions}>
      <div className='__container'>
        <div className={style.transactionsTop}>
          <Title.H1 className={style.transactionsTop__title}>
            Transactions
          </Title.H1>
          <Button className={style.transactionsTop__button}>
            See more
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1.61342 8.47415C1.52262 8.33039 1.47723 8.25851 1.45182 8.14764C1.43273 8.06436 1.43273 7.93303 1.45182 7.84975C1.47723 7.73889 1.52262 7.66701 1.61341 7.52325C2.36369 6.33526 4.59693 3.33203 8.00027 3.33203C11.4036 3.33203 13.6369 6.33526 14.3871 7.52325C14.4779 7.66701 14.5233 7.73889 14.5487 7.84975C14.5678 7.93303 14.5678 8.06436 14.5487 8.14764C14.5233 8.25851 14.4779 8.33039 14.3871 8.47415C13.6369 9.66214 11.4036 12.6654 8.00027 12.6654C4.59693 12.6654 2.36369 9.66214 1.61342 8.47415Z'
                stroke='white'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
              <path
                d='M8.00027 9.9987C9.10484 9.9987 10.0003 9.10327 10.0003 7.9987C10.0003 6.89413 9.10484 5.9987 8.00027 5.9987C6.8957 5.9987 6.00027 6.89413 6.00027 7.9987C6.00027 9.10327 6.8957 9.9987 8.00027 9.9987Z'
                stroke='white'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          </Button>
        </div>
        <Table.Container scrolled className={style.transactions__table}>
          <Table.Head>
            <Table.Row>
              <Table.Col sort>Date</Table.Col>
              <Table.Col sort center>
                Transaction
              </Table.Col>
              <Table.Col sort center>
                Native price
              </Table.Col>
              <Table.Col sort center>
                Current price
              </Table.Col>
              <Table.Col sort center>
                Maker
              </Table.Col>
              {/*<Table.Col sort right>
                Price
              </Table.Col>
               <Table.Col sort right>
                Total
              </Table.Col>
              <Table.Col sort right>
                Price BSC
              </Table.Col>
              <Table.Col sort right>
                Amount Lope
              </Table.Col>
              <Table.Col sort right>
                Amount BSC
              </Table.Col>
              <Table.Col sort right>
                Maker
              </Table.Col> */}
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {transactions?.map((item, index) => {
              console.log(item, 'itemmmmmmmmmmmm');
              return (
                <Table.Row key={index}>
                  <Table.Col>
                    <Text>{item?.date}</Text>
                  </Table.Col>
                  <Table.Col center>
                    <div
                      className={`${style.transactionType} ${style.item?.type}`}
                    >
                      {item?.type}
                    </div>
                  </Table.Col>
                  <Table.Col center>
                    <Text>{item?.native_amount}</Text>
                  </Table.Col>
                  <Table.Col right>
                    <Text>{item?.current_amount}</Text>
                  </Table.Col>
                  <Table.Col right>{`${item?.maker.slice(
                    0,
                    6
                  )}...${item?.maker.slice(-6)}`}</Table.Col>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table.Container>
      </div>
    </div>
  );
};

export default Transactions;
