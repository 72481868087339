import { useState } from 'react';
import { Controller } from 'react-hook-form';
import Input from '../../../../components/Input/Input';
import Switcher from '../../../../components/Switcher/Switcher';
import Textarea from '../../../../components/Textarea/Textarea';
import SubmitCoinCard from '../../modules/Card/Card';
import Text from '../../../../components/Text/Text';
import Button from '../../../../components/Button/Button';
import Select from '../../../../components/Select/Select';
import style from './About.module.scss';
import '../../../../scss/style.scss';

const AboutSection = ({ control, watch, errors }) => {
  const [isSwitched, setIsSwitched] = useState(false);

  const onSwitch = ({ target }) => {
    setIsSwitched(target.checked);
  };

  return (
    <SubmitCoinCard className={style.about} title={'About Project'}>
      <div className={style.aboutBody}>
        <Switcher
          handler={onSwitch}
          className={style.aboutBody__switcher}
          text={['Default', 'Presale']}
        />
        {isSwitched && (
          <>
            <Controller
              control={control}
              name='presale_link'
              rules={{
                required: false,
              }}
              render={({ field: { onChange, name, value } }) => {
                return (
                  <Input
                    onChange={onChange}
                    value={value}
                    big
                    border
                    placeholder={'Presale link (Optional)'}
                    icon={
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M5.99992 11.3337H4.66659C2.82564 11.3337 1.33325 9.84127 1.33325 8.00033C1.33325 6.15938 2.82564 4.66699 4.66659 4.66699H5.99992M9.99992 11.3337H11.3333C13.1742 11.3337 14.6666 9.84127 14.6666 8.00033C14.6666 6.15938 13.1742 4.66699 11.3333 4.66699H9.99992M4.66659 8.00033L11.3333 8.00033'
                          stroke='white'
                          stroke-opacity='0.5'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </svg>
                    }
                  />
                );
              }}
            />
            <Text grey>Project is a Fair Launch?</Text>
            <Controller
              control={control}
              name='fare_launch'
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <div className={style.row}>
                    <Button
                      lg
                      filled={value === 'yes'}
                      onClick={() => onChange('yes')}
                    >
                      Yes
                    </Button>
                    <Button
                      lg
                      filled={value === 'no'}
                      onClick={() => onChange('no')}
                    >
                      No
                    </Button>
                  </div>
                );
              }}
            />
            <Text grey>Softcap (Optional)</Text>
            <div className={style.row}>
              <Controller
                control={control}
                name='softcap'
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Input
                      big
                      border
                      placeholder={'100'}
                      onChange={onChange}
                      value={value}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name='softcap_platform'
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      onChange={onChange}
                      value={value}
                      big
                      border
                      options={[
                        {
                          name: 'ETH',
                          value: '',
                        },
                        {
                          name: 'ETH',
                          value: 'ETH',
                        },
                      ]}
                    />
                  );
                }}
              />
            </div>
            <Text grey>Softcap (Optional)</Text>
            <Controller
              control={control}
              name='softcap_custom'
              rules={{
                required: false,
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    big
                    border
                    placeholder={'200 ETH'}
                    onChange={onChange}
                    value={value}
                  />
                );
              }}
            />
            <Text grey>Presale Start Date (UTC)</Text>
            <div className={style.row}>
              <Controller
                control={control}
                name='start_time'
                rules={{
                  required: 'Required',
                }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      onChange={onChange}
                      value={value}
                      className={style.row__select}
                      big
                      border
                      options={[
                        {
                          name: 'Time',
                          value: '',
                        },
                        {
                          name: '10:00',
                          value: '10',
                        },
                        {
                          name: '11:00',
                          value: '11',
                        },
                        {
                          name: '12:00',
                          value: '12',
                        },
                      ]}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name='start_day'
                rules={{
                  required: 'Required',
                }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      onChange={onChange}
                      value={value}
                      className={style.row__select}
                      big
                      border
                      options={[
                        {
                          name: 'Day',
                          value: '',
                        },
                        {
                          name: '1',
                          value: '1',
                        },
                        {
                          name: '2',
                          value: '2',
                        },
                        {
                          name: '3',
                          value: '3',
                        },
                      ]}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name='start_month'
                rules={{
                  required: 'Required',
                }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      className={style.row__select}
                      onChange={onChange}
                      value={value}
                      big
                      border
                      options={[
                        {
                          name: 'Month',
                          value: '',
                        },
                        {
                          name: 'January',
                          value: 'january',
                        },
                        {
                          name: 'February',
                          value: 'february',
                        },
                        {
                          name: 'March',
                          value: 'march',
                        },
                      ]}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name='start_year'
                rules={{
                  required: 'Required',
                }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      onChange={onChange}
                      value={value}
                      className={style.row__select}
                      big
                      border
                      options={[
                        {
                          name: 'Year',
                          value: '',
                        },
                        {
                          name: '2024',
                          value: '2024',
                        },
                        {
                          name: '2025',
                          value: '2025',
                        },
                        {
                          name: '2026',
                          value: '2026',
                        },
                      ]}
                    />
                  );
                }}
              />
            </div>
            {errors?.start_time?.message ||
              errors?.start_day?.message ||
              errors?.start_month?.message ||
              (errors?.start_year?.message && (
                <label className='error-message'>Required</label>
              ))}
            <Text grey>Presale End Date (UTC) (Optional)</Text>
            <div className={style.row}>
              <Controller
                control={control}
                name='end_time'
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      onChange={onChange}
                      value={value}
                      className={style.row__select}
                      big
                      border
                      options={[
                        {
                          name: 'Time',
                          value: '',
                        },
                        {
                          name: '10:00',
                          value: '10',
                        },
                        {
                          name: '11:00',
                          value: '11',
                        },
                        {
                          name: '12:00',
                          value: '12',
                        },
                      ]}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name='end_day'
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      onChange={onChange}
                      value={value}
                      className={style.row__select}
                      big
                      border
                      options={[
                        {
                          name: 'Day',
                          value: '',
                        },
                        {
                          name: '1',
                          value: '1',
                        },
                        {
                          name: '2',
                          value: '2',
                        },
                        {
                          name: '3',
                          value: '3',
                        },
                      ]}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name='end_month'
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      className={style.row__select}
                      onChange={onChange}
                      value={value}
                      big
                      border
                      options={[
                        {
                          name: 'Month',
                          value: '',
                        },
                        {
                          name: 'January',
                          value: 'january',
                        },
                        {
                          name: 'February',
                          value: 'february',
                        },
                        {
                          name: 'March',
                          value: 'march',
                        },
                      ]}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name='end_year'
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      onChange={onChange}
                      value={value}
                      className={style.row__select}
                      big
                      border
                      options={[
                        {
                          name: 'Year',
                          value: '',
                        },
                        {
                          name: '2024',
                          value: '2024',
                        },
                        {
                          name: '2025',
                          value: '2025',
                        },
                        {
                          name: '2026',
                          value: '2026',
                        },
                      ]}
                    />
                  );
                }}
              />
            </div>
            <Text grey>Launch Date is known?</Text>
            <Controller
              control={control}
              name='launch_date_is_known'
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <div className={style.row}>
                    <Button
                      lg
                      filled={value === 'yes'}
                      onClick={() => onChange('yes')}
                    >
                      Yes
                    </Button>
                    <Button
                      lg
                      filled={value === 'no'}
                      onClick={() => onChange('no')}
                    >
                      No
                    </Button>
                  </div>
                );
              }}
            />
            {watch('launch_date_is_known') === 'yes' && (
              <div className={style.row}>
                <Controller
                  control={control}
                  name='launch_time'
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        onChange={onChange}
                        value={value}
                        className={style.row__select}
                        big
                        border
                        options={[
                          {
                            name: 'Time',
                            value: '',
                          },
                          {
                            name: '10:00',
                            value: '10',
                          },
                          {
                            name: '11:00',
                            value: '11',
                          },
                          {
                            name: '12:00',
                            value: '12',
                          },
                        ]}
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name='launch_day'
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        onChange={onChange}
                        value={value}
                        className={style.row__select}
                        big
                        border
                        options={[
                          {
                            name: 'Day',
                            value: '',
                          },
                          {
                            name: '1',
                            value: '1',
                          },
                          {
                            name: '2',
                            value: '2',
                          },
                          {
                            name: '3',
                            value: '3',
                          },
                        ]}
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name='launch_month'
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        className={style.row__select}
                        onChange={onChange}
                        value={value}
                        big
                        border
                        options={[
                          {
                            name: 'Month',
                            value: '',
                          },
                          {
                            name: 'January',
                            value: 'january',
                          },
                          {
                            name: 'February',
                            value: 'february',
                          },
                          {
                            name: 'March',
                            value: 'march',
                          },
                        ]}
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name='launch_year'
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        onChange={onChange}
                        value={value}
                        className={style.row__select}
                        big
                        border
                        options={[
                          {
                            name: 'Year',
                            value: '',
                          },
                          {
                            name: '2024',
                            value: '2024',
                          },
                          {
                            name: '2025',
                            value: '2025',
                          },
                          {
                            name: '2026',
                            value: '2026',
                          },
                        ]}
                      />
                    );
                  }}
                />
              </div>
            )}
            {watch('launch_date_is_known') === 'yes' &&
              (errors?.launch_time ||
                errors?.launch_day ||
                errors?.launch_month ||
                errors?.launch_year) && (
                <label className='error-message'>Required</label>
              )}
            <Controller
              control={control}
              name='custom_chart_link'
              rules={{
                required: false,
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    onChange={onChange}
                    value={value}
                    big
                    border
                    placeholder={'Custom chart link (Optional)'}
                    info={'Custom chart link (Optional)'}
                    icon={
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M5.99992 11.3337H4.66659C2.82564 11.3337 1.33325 9.84127 1.33325 8.00033C1.33325 6.15938 2.82564 4.66699 4.66659 4.66699H5.99992M9.99992 11.3337H11.3333C13.1742 11.3337 14.6666 9.84127 14.6666 8.00033C14.6666 6.15938 13.1742 4.66699 11.3333 4.66699H9.99992M4.66659 8.00033L11.3333 8.00033'
                          stroke='white'
                          stroke-opacity='0.5'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </svg>
                    }
                  />
                );
              }}
            />
            <Controller
              control={control}
              name='custom_swap_link'
              rules={{
                required: false,
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    onChange={onChange}
                    value={value}
                    big
                    border
                    placeholder={'Custom swap link (Optional)'}
                    info={'Custom swap link (Optional)'}
                    icon={
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M5.99992 11.3337H4.66659C2.82564 11.3337 1.33325 9.84127 1.33325 8.00033C1.33325 6.15938 2.82564 4.66699 4.66659 4.66699H5.99992M9.99992 11.3337H11.3333C13.1742 11.3337 14.6666 9.84127 14.6666 8.00033C14.6666 6.15938 13.1742 4.66699 11.3333 4.66699H9.99992M4.66659 8.00033L11.3333 8.00033'
                          stroke='white'
                          stroke-opacity='0.5'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </svg>
                    }
                  />
                );
              }}
            />
          </>
        )}
        <>
          <Controller
            control={control}
            name='contract_address'
            rules={{
              required: 'Required',
            }}
            render={({ field: { onChange, name, value } }) => {
              return (
                <>
                  <Input
                    onChange={onChange}
                    value={value}
                    big
                    border
                    placeholder={'Contract Address'}
                    icon={
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M9.33341 7.33301H5.33341M6.66675 9.99967H5.33341M10.6667 4.66634H5.33341M13.3334 4.53301V11.4663C13.3334 12.5864 13.3334 13.1465 13.1154 13.5743C12.9237 13.9506 12.6177 14.2566 12.2414 14.4484C11.8136 14.6663 11.2535 14.6663 10.1334 14.6663H5.86675C4.74664 14.6663 4.18659 14.6663 3.75877 14.4484C3.38244 14.2566 3.07648 13.9506 2.88473 13.5743C2.66675 13.1465 2.66675 12.5864 2.66675 11.4663V4.53301C2.66675 3.4129 2.66675 2.85285 2.88473 2.42503C3.07648 2.0487 3.38244 1.74274 3.75877 1.55099C4.18659 1.33301 4.74664 1.33301 5.86675 1.33301H10.1334C11.2535 1.33301 11.8136 1.33301 12.2414 1.55099C12.6177 1.74274 12.9237 2.0487 13.1154 2.42503C13.3334 2.85285 13.3334 3.4129 13.3334 4.53301Z'
                          stroke='white'
                          stroke-opacity='0.5'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </svg>
                    }
                  />
                  {errors?.contract_address && (
                    <label className='error-message'>
                      {errors?.contract_address?.message}
                    </label>
                  )}
                </>
              );
            }}
          />
          <Controller
            control={control}
            name='description'
            rules={{
              required: 'Required',
            }}
            render={({ field: { onChange, name, value } }) => {
              return (
                <>
                  <Textarea
                    border
                    placeholder={'Description'}
                    onChange={onChange}
                    value={value}
                  />
                  {errors?.description && (
                    <label className='error-message'>
                      {errors?.description?.message}
                    </label>
                  )}
                </>
              );
            }}
          />
          <Controller
            control={control}
            name='youtube_link'
            rules={{
              required: 'Required',
            }}
            render={({ field: { onChange, name, value } }) => {
              return (
                <>
                  <Input
                    onChange={onChange}
                    value={value}
                    big
                    border
                    placeholder={'YouTube link'}
                    icon={
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M5.99992 11.3337H4.66659C2.82564 11.3337 1.33325 9.84127 1.33325 8.00033C1.33325 6.15938 2.82564 4.66699 4.66659 4.66699H5.99992M9.99992 11.3337H11.3333C13.1742 11.3337 14.6666 9.84127 14.6666 8.00033C14.6666 6.15938 13.1742 4.66699 11.3333 4.66699H9.99992M4.66659 8.00033L11.3333 8.00033'
                          stroke='white'
                          stroke-opacity='0.5'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </svg>
                    }
                  />
                  {errors?.youtube_link && (
                    <label className='error-message'>
                      {errors?.youtube_link?.message}
                    </label>
                  )}
                </>
              );
            }}
          />

          <Controller
            control={control}
            name='coingecko_link'
            rules={{
              required: 'Required',
            }}
            render={({ field: { onChange, name, value } }) => {
              return (
                <>
                  <Input
                    onChange={onChange}
                    value={value}
                    big
                    border
                    placeholder={'CoinGecko link'}
                    icon={
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M5.99992 11.3337H4.66659C2.82564 11.3337 1.33325 9.84127 1.33325 8.00033C1.33325 6.15938 2.82564 4.66699 4.66659 4.66699H5.99992M9.99992 11.3337H11.3333C13.1742 11.3337 14.6666 9.84127 14.6666 8.00033C14.6666 6.15938 13.1742 4.66699 11.3333 4.66699H9.99992M4.66659 8.00033L11.3333 8.00033'
                          stroke='white'
                          stroke-opacity='0.5'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </svg>
                    }
                  />
                  {errors?.coingecko_link && (
                    <label className='error-message'>
                      {errors?.coingecko_link?.message}
                    </label>
                  )}
                </>
              );
            }}
          />

          <Controller
            control={control}
            name='pancakeswap_link'
            rules={{
              required: 'Required',
            }}
            render={({ field: { onChange, name, value } }) => {
              return (
                <>
                  <Input
                    onChange={onChange}
                    value={value}
                    big
                    border
                    placeholder={'PancakeSwap link'}
                    icon={
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M5.99992 11.3337H4.66659C2.82564 11.3337 1.33325 9.84127 1.33325 8.00033C1.33325 6.15938 2.82564 4.66699 4.66659 4.66699H5.99992M9.99992 11.3337H11.3333C13.1742 11.3337 14.6666 9.84127 14.6666 8.00033C14.6666 6.15938 13.1742 4.66699 11.3333 4.66699H9.99992M4.66659 8.00033L11.3333 8.00033'
                          stroke='white'
                          stroke-opacity='0.5'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </svg>
                    }
                  />
                  {errors?.pancakeswap_link && (
                    <label className='error-message'>
                      {errors?.pancakeswap_link?.message}
                    </label>
                  )}
                </>
              );
            }}
          />

          <Controller
            control={control}
            name='poocoin_link'
            rules={{
              required: 'Required',
            }}
            render={({ field: { onChange, name, value } }) => {
              return (
                <>
                  <Input
                    onChange={onChange}
                    value={value}
                    big
                    border
                    placeholder={'PooCoin link'}
                    icon={
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M5.99992 11.3337H4.66659C2.82564 11.3337 1.33325 9.84127 1.33325 8.00033C1.33325 6.15938 2.82564 4.66699 4.66659 4.66699H5.99992M9.99992 11.3337H11.3333C13.1742 11.3337 14.6666 9.84127 14.6666 8.00033C14.6666 6.15938 13.1742 4.66699 11.3333 4.66699H9.99992M4.66659 8.00033L11.3333 8.00033'
                          stroke='white'
                          stroke-opacity='0.5'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </svg>
                    }
                  />
                  {errors?.poocoin_link && (
                    <label className='error-message'>
                      {errors?.poocoin_link?.message}
                    </label>
                  )}
                </>
              );
            }}
          />
        </>
      </div>
    </SubmitCoinCard>
  );
};

export default AboutSection;
