import Calendar from '../../../components/Calendar/Calendar';
import style from '../Banner/index.module.scss';
import icon from '../../../components/modals/PromotedSpot/assets/img.svg';
import projectIcon from '../../../components/modals/PromotedSpot/assets/project-img.jpg';
import stars from '../../../components/modals/Banner/assets/stars.svg';
import Title from '../../../components/Title/Title';
import Text from '../../../components/Text/Text';
import UploadImg from '../../../components/UploadImg/UploadImg';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';
import MobilePageHeading from '../../../components/MobilePageHeading/MobilePageHeading';
import Select from '../../../components/Select/Select';

const PromotedSpotPage = () => {
  return (
    <div className={style.block}>
      <div className='__container'>
        <div className={style.blockBody}>
          <MobilePageHeading
            title={'Reserve order'}
            text={'Pick available days and upload resources'}
          />
          <Calendar className={style.blockBody__calendar} />
          <div className={style.banner}>
            <div className={`${style.banner__icon} ${style.spot}`}>
              <img src={icon} alt='' />

              <div className={style.banner__iconStars}>
                <img src={stars} alt='' />
              </div>
            </div>
            <Title.H2 className={`${style.banner__title} ${style.spot}`}>
              Promoted Spot
            </Title.H2>
            <Text grey center>
              Your coin will appear in <br /> Promoted section on main page
            </Text>
            <Select
              className={style.banner__select}
              big
              border
              isProjectList
              options={[
                {
                  name: 'Select your project',
                  value: '',
                },
                {
                  icon: projectIcon,
                  name: 'Memereum',
                  category: 'Meme',
                  chain: 'BSC',
                  address: '0xDbC3...e382',
                  value: 'project_1',
                },
                {
                  icon: projectIcon,
                  name: 'Memereum',
                  category: 'Meme',
                  chain: 'BSC',
                  address: '0xDbC3...e382',
                  value: 'project_1',
                },
                {
                  icon: projectIcon,
                  name: 'Memereum',
                  category: 'Meme',
                  chain: 'BSC',
                  address: '0xDbC3...e382',
                  value: 'project_1',
                },
                {
                  icon: projectIcon,
                  name: 'Memereum',
                  category: 'Meme',
                  chain: 'BSC',
                  address: '0xDbC3...e382',
                  value: 'project_1',
                },
                {
                  icon: projectIcon,
                  name: 'Memereum',
                  category: 'Meme',
                  chain: 'BSC',
                  address: '0xDbC3...e382',
                  value: 'project_1',
                },
                {
                  icon: projectIcon,
                  name: 'Memereum',
                  category: 'Meme',
                  chain: 'BSC',
                  address: '0xDbC3...e382',
                  value: 'project_1',
                },
                {
                  icon: projectIcon,
                  name: 'Memereum',
                  category: 'Meme',
                  chain: 'BSC',
                  address: '0xDbC3...e382',
                  value: 'project_1',
                },
                {
                  icon: projectIcon,
                  name: 'Memereum',
                  category: 'Meme',
                  chain: 'BSC',
                  address: '0xDbC3...e382',
                  value: 'project_1',
                },
              ]}
            />
            <Button className={style.banner__add} filled>
              Add to Cart
            </Button>
            <Button noBorder whiteText>
              Show position
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromotedSpotPage;
