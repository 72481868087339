import Title from '../../../../components/Title/Title';
import Promoted from '../../modules/Promoted/Promoted';
import style from './PromotedSection.module.scss';
import img from './assets/card-icon.jpg';

const cards = [
  { img: img, name: 'SPONGEV2' },
  { img: img, name: 'SPONGEV2' },
  { img: img, name: 'SPONGEV2' },
  { img: img, name: 'SPONGEV2' },
  { img: img, name: 'SPONGEV2' },
  { img: img, name: 'SPONGEV2' },
  { img: img, name: 'SPONGEV2' },
  { img: img, name: 'SPONGEV2' },
  { img: img, name: 'SPONGEV2' },
  { img: img, name: 'SPONGEV2' },
  { img: img, name: 'SPONGEV2' },
  { img: img, name: 'SPONGEV2' },
  { img: img, name: 'SPONGEV2' },
  { img: img, name: 'SPONGEV2' },
  { img: img, name: 'SPONGEV2' },
  { img: img, name: 'SPONGEV2' },
  { img: img, name: 'SPONGEV2' },
  { img: img, name: 'SPONGEV2' },
  { img: img, name: 'SPONGEV2' },
  { img: img, name: 'SPONGEV2' },
  { img: img, name: 'SPONGEV2' },
  { img: img, name: 'SPONGEV2' },
  { img: img, name: 'SPONGEV2' },
  { img: img, name: 'SPONGEV2' },
  { img: img, name: 'SPONGEV2' },
  { img: img, name: 'SPONGEV2' },
];

const PromotedSection = () => {
  return (
    <div className={style.promoted}>
      {/* <div className='__container'> */}
      <Title.H1 className={style.promoted__title}>Promoted</Title.H1>
      <Promoted className={style.promoted__slider} cards={cards} />
      {/* </div> */}
    </div>
  );
};

export default PromotedSection;
